import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DAOMap {
  type: string,
  slot?: number,
  trueFalseVote: boolean,
  getCounterRequest?: (id: number) => Promise<any>,
  getRequest: (id: number) => Promise<any>,
  makeVote: (id: number, type?: boolean) => Promise<any>,
  approveRequest: (id: number) => Promise<any>,
  cancelRequest?: (id: number) => Promise<any>,
  parseData?: (data: any) => Promise<any> | ({status: number, requestData?: any[]}),
  isRequestAvailable?: (id: number) => Promise<any>,
}

interface DAOMapSlice {
  daoBridgeMap: DAOMap[] |[],
  daoManageMap: DAOMap[] | [],
  daoPackagesMap: DAOMap[] | [],
  daoTreasuryNewMap: DAOMap[] | [],
  daoTreasuryOldMap: DAOMap[] | [],
  daoRevenueShareMap: DAOMap[] | [],
  daoUniswapMap: DAOMap[] | [],
  daoWulxMap: DAOMap[] | []
}

const initialState: DAOMapSlice = {
  daoBridgeMap: [],
  daoManageMap: [],
  daoPackagesMap: [],
  daoTreasuryNewMap: [],
  daoTreasuryOldMap: [],
  daoRevenueShareMap: [],
  daoUniswapMap: [],
  daoWulxMap: []
}

export const daoMapsSlice = createSlice({
  name: 'daoMaps',
  initialState,
  reducers: {
    setBridgeMap(state, action: PayloadAction<DAOMap[]>) {
      state.daoBridgeMap = action.payload
    },
    setManageMap(state, action: PayloadAction<DAOMap[]>) {
      state.daoManageMap = action.payload
    },
    setPackagesMap(state, action: PayloadAction<DAOMap[]>) {
      state.daoPackagesMap = action.payload
    },
    setTreasuryNewMap(state, action: PayloadAction<DAOMap[]>) {
      state.daoTreasuryNewMap = action.payload
    },
    setTreasuryOldMap(state, action: PayloadAction<DAOMap[]>) {
      state.daoTreasuryOldMap = action.payload
    },
    setRevenueShareMap(state, action: PayloadAction<DAOMap[]>) {
      state.daoRevenueShareMap = action.payload
    },
    setUniswapMap(state, action: PayloadAction<DAOMap[]>) {
      state.daoUniswapMap = action.payload
    },
    setWulxMap(state, action: PayloadAction<DAOMap[]>) {
      state.daoWulxMap = action.payload
    },
  }
})

export default daoMapsSlice.reducer
