export const daoStatuses = {
  0: 'canceled',
  1: 'active',
  2: 'executed'
}

export const shortStatuses = {
  0: 'active',
  1: 'executed'
}

export const daoDepositStatuses  = {
  0: 'notCreated',
  1: 'canceled',
  2: 'active',
  3: 'executed'
}