import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface WalletSlice {
  isConnect: boolean,
  address: string
}

const initialState: WalletSlice = {
  isConnect: false,
  address: '0x0000000000000000000000000000000000000000',
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletInfo(state, action: PayloadAction<WalletSlice>) {
      state.isConnect = action.payload.isConnect
      state.address = action.payload.address
    },
  }
})

export const {setWalletInfo} = walletSlice.actions
export default walletSlice.reducer