import React from 'react'
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import { useMetaMask } from 'metamask-react';

import routes from '../utils/constants/routes';

const drawerWidth = 240

const Panel: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const mm = useMetaMask()
  React.useEffect(() => {
    console.log(mm)
  })
  return (
    <Grid container spacing={2}>
      <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            DAO management
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            { routes.map((item) => (
              <ListItem
                key={item.id}
                button
                selected={pathname === item.link}
                onClick={() => navigate(item.link)}
              >
                <ListItemIcon>
                  <DataUsageIcon />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3, pt: 6 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Grid>
  )
}

export default Panel
