import { Backdrop, Box, CircularProgress} from '@mui/material'

export const CircularLoader = ({open}: any) => {

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress />
      </Box>
    </Backdrop>
  )
}