import { BigNumber, BytesLike, Contract, ethers } from 'ethers'
import { Hexable } from 'ethers/lib/utils';
import { abiToken } from '../constant/token/tokenAbi'
import { DAOBridgeAbiFee } from '../constant/gas-fee-abis/DAOBridgeAbiFee'
import { bridgeAbiFee } from '../constant/gas-fee-abis/bridgeAbiFee'
import { ERC20HandlerAbiFee } from '../constant/gas-fee-abis/ERC20HandlerAbiFee'
import { DAOBridgeAbiFeeSetter } from '../constant/gas-fee-setter-abis/DAOBridgeAbiFeeSetter'
import { bridgeAbiFeeSetter } from '../constant/gas-fee-setter-abis/bridgeAbiFeeSetter'
import { ERC20HandlerAbiFeeSetter } from '../constant/gas-fee-setter-abis/ERC20HandlerAbiFeeSetter'
import { DAOBridgeAbiNoFee } from '../constant/without-gas-fee-abis/DAOBridgeAbiNoFee'
import { bridgeAbiNoFee } from '../constant/without-gas-fee-abis/bridgeAbiNoFee'
import { ERC20HandlerAbiNoFee } from '../constant/without-gas-fee-abis/ERC20HandlerAbiNoFee'
import { fantomTestNetId, ultronChainId, ultronChainIdTestNet } from '../constant/chain/chain'
import { config } from '../app-config'


export const errorHandler = (func, error) => {
  const message = error.data?.message || error.message || 'something went wrong'
  func({open: true, type: 'warning', text: message})
}

export const cutUserAddress = (address: string | null) => {
  if (address !== null) {
    return address.toString().substring(0, 6) + '...' + address.toString().substr(address.length - 4);
  } else {
    return '0x00...0000';
  }
}

export const toWei = (value) => ethers.utils.parseEther(value.toString());



export const getSlotKey = async (key, slot) => {
  const bKey = BigNumber.from(key);
  const storageSlot = BigNumber.from(slot);
  const newKeyPreimage = ethers.utils.concat([
    // Mappings' keys in Solidity must all be word-aligned (32 bytes)
    ethers.utils.hexZeroPad(bKey.toHexString(), 32),

    // Similarly with the slot-index into the Solidity variable layout
    ethers.utils.hexZeroPad(storageSlot.toHexString(), 32),
  ]);

  return ethers.utils.keccak256(newKeyPreimage)
}


export const parseTokenAmountWithDecimals = async (address, amount, signer) => {
  try {
    const Token = new Contract(address, abiToken, signer)
    const decimals = await Token.decimals()
    return ethers.utils.parseUnits(amount, decimals)
  } catch (err) {
    return ''
  }
}

export const parseTokenAmount = (amount, decimals) => ethers.utils.parseUnits(amount, decimals)

export const tokenAmountToInt = async (tokenAddress, amount, signer) => {
  let intAmount = ''
  try {
    const Token = new Contract(tokenAddress, abiToken, signer)
    const decimals = await Token.decimals()
    intAmount = ethers.utils.formatUnits(amount, decimals)
  }  catch (er) {
    console.log(er)
  }
  return intAmount
}

export const toHex = (covertThis: string | number | bigint | BytesLike | Hexable, padding: number) => {
  return ethers.utils.hexZeroPad(ethers.utils.hexlify(covertThis), padding);
};

export const createResourceID = (contractAddress: string, domainId: any) => {
  return toHex(contractAddress + toHex(domainId, 1).substr(2), 32)
};

export const createERCWithdrawData = async (tokenAddress: string, recipientAddress: string, tokenAmount: string | number | bigint | BytesLike | Hexable, signer: any) => {
  // @ts-ignore
  const token = await parseTokenAmountWithDecimals(tokenAddress, tokenAmount, signer)
  return '0x' +
    toHex(tokenAddress, 32).substr(2) +
    toHex(recipientAddress, 32).substr(2) +
    toHex(token, 32).substr(2);
}


export const getAbi = (chainId) => {
  const {ethereumChainId, bscChainId, avalancheChainId, polygonChainId, fantomChainId, baseChainId} = config
  const chainList = [ethereumChainId, bscChainId, avalancheChainId, polygonChainId, fantomChainId, baseChainId]

  if(chainId === ultronChainId || chainId === ultronChainIdTestNet) {
    return {daoAbi: DAOBridgeAbiFee, bridgeAbi: bridgeAbiFee, erc20handlerAbi: ERC20HandlerAbiFee}
  } else if(chainId === fantomTestNetId) {
    return {daoAbi: DAOBridgeAbiFeeSetter, bridgeAbi: bridgeAbiFeeSetter, erc20handlerAbi: ERC20HandlerAbiFeeSetter}
  } else if(chainList.includes(chainId)){
    return {daoAbi: DAOBridgeAbiNoFee, bridgeAbi: bridgeAbiNoFee, erc20handlerAbi: ERC20HandlerAbiNoFee}
  }
}
