import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface RequestObj {
  id: number,
  request: object,
  type: string,
  votes: number,
  hasRejectedButton: boolean,
  haveCancelFunc: boolean,
  isAvailable: boolean
}

interface PayloadObj {
  requests: RequestObj[],
  voters: string[]
}
interface DaoRequestsSlice {
  bridgeRequests: RequestObj[],
  bridgeVoters: string[],

  manageRequests: RequestObj[],
  manageVoters: string[],

  packagesRequests: RequestObj[],
  packagesVoters: string[],

  treasuryNewRequests: RequestObj[],
  treasuryNewVoters: string[],

  treasuryOldRequests: RequestObj[],
  treasuryOldVoters: string[],

  revenueShareRequests: RequestObj[],
  revenueShareVoters: string[],

  uniswapRequests: RequestObj[],
  uniswapVoters: string[],

  wulxRequests: RequestObj[],
  wulxVoters: string[],
}

const initialState: DaoRequestsSlice = {
  bridgeRequests: [],
  bridgeVoters: [],
  manageRequests: [],
  manageVoters: [],
  packagesRequests: [],
  packagesVoters: [],
  treasuryNewRequests: [],
  treasuryNewVoters: [],
  treasuryOldRequests: [],
  treasuryOldVoters: [],
  revenueShareRequests: [],
  revenueShareVoters: [],
  uniswapRequests: [],
  uniswapVoters: [],
  wulxRequests: [],
  wulxVoters: [],
}

export const daoRequestsSlice = createSlice({
  name: 'daoRequests',
  initialState,
  reducers: {
    setBridgeData(state, action: PayloadAction<PayloadObj>) {
      state.bridgeRequests = action.payload.requests
      state.bridgeVoters = action.payload.voters
    },
    setManageData(state, action: PayloadAction<PayloadObj>) {
      state.manageRequests = action.payload.requests
      state.manageVoters = action.payload.voters
    },
    setPackagesData(state, action: PayloadAction<PayloadObj>) {
      state.packagesRequests = action.payload.requests
      state.packagesVoters = action.payload.voters
    },
    setTreasuryNewData(state, action: PayloadAction<PayloadObj>) {
      state.treasuryNewRequests = action.payload.requests
      state.treasuryNewVoters = action.payload.voters
    },
    setTreasuryOldData(state, action: PayloadAction<PayloadObj>) {
      state.treasuryOldRequests = action.payload.requests
      state.treasuryOldVoters = action.payload.voters
    },
    setRevenueShareData(state, action: PayloadAction<PayloadObj>) {
      state.revenueShareRequests = action.payload.requests
      state.revenueShareVoters = action.payload.voters
    },
    setUniswapData(state, action: PayloadAction<PayloadObj>) {
      state.uniswapRequests = action.payload.requests
      state.uniswapVoters = action.payload.voters
    },
    setWulxData(state, action: PayloadAction<PayloadObj>) {
      state.wulxRequests = action.payload.requests
      state.wulxVoters = action.payload.voters
    },
  }
})

export default daoRequestsSlice.reducer
