
const routes: { id: number, label: string, link: string }[] = [
    {
        id: 0,
        label: 'DAO Bridge',
        link: '/',
    },
    {
        id: 1,
        label: 'DAO wulx',
        link: '/dao-wulx',
    },
    {
        id: 2,
        label: 'DAO Manage',
        link: '/dao-manage',
    },
    {
        id: 3,
        label: 'DAO Packages',
        link: '/dao-packages',
    },
    {
        id: 4,
        label: 'DAO treasury old',
        link: '/dao-treasury-old',
    },
    {
        id: 5,
        label: 'DAO Uniswap',
        link: '/dao-uniswap',
    },
    {
        id: 6,
        label: 'DAO treasury new',
        link: '/dao-treasury-new',
    },
    {
        id: 7,
        label: 'DAO revenue share',
        link: '/dao-revenue-share',
    },
    {
        id: 8,
        label: 'DAO cold treasury deposit',
        link: '/dao-cold-treasury-deposit',
    },
    {
        id: 9,
        label: 'DAO hot treasury deposit',
        link: '/dao-hot-treasury-deposit',
    },
    {
        id: 10,
        label: 'DAO smart wallet factory deposit',
        link: '/dao-smart-wallet-factory-deposit',
    },
]

export default routes;
