export const makeDaoColdTreasuryDepositObject = (dao) => {
  const mapDAO = [
    {
      type: 'voterRequest',
      name: 'Voter',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getVoterRequestsCounter(),
      getRequest: async (id) => await dao.getVoterRequest(id),
      getRequestVotes: async (id) => await dao.countGetVotersAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForVoterRequest(type, id),
      cancelRequest: async (id) => await dao.cancelVoterRequest(id),
      approveRequest: async (id) => await dao.votersRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newVoterRequest(!!proposalData.include, proposalData.voterAddress),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: data.include ? 'Add voter' : 'Remove voter' },
            { label: `Address: ${data.candidate}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Include?',
          xs: 2,
          type: 'boolean',
          defaultValue: 1,
          daoName: 'tokenAddress',
          formLink: 'include',
        },
        {
          name: 'Voter Address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'voterAddress',
          formLink: 'voterAddress',
        },
      ],
    },
    {
      type: 'ChangeAdmin',
      name: 'Change admin',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getAdminRequestsCounter(),
      getRequest: async (id) => await dao.getAdminRequest(id),
      getRequestVotes: async (id) => await dao.countGetAdminsAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForAdminRequest(type, id),
      cancelRequest: async (id) => await dao.cancelAdminRequest(id),

      approveRequest: async (id) => await dao.adminsRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newAdminRequest(!!proposalData.include, proposalData.adminAddress),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: data.include ? 'Add admin' : 'Remove admin' },
            { label: `Address: ${data.candidate}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Include?',
          xs: 2,
          type: 'boolean',
          defaultValue: 1,
          daoName: 'tokenAddress',
          formLink: 'include',
        },
        {
          name: 'Admin Address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'adminAddress',
          formLink: 'adminAddress',
        },
      ],
    },
    {
      type: 'ChangeThresholdPercent',
      name: 'Change threshold percent',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeThresholdPercentRequestsCounter(),
      getRequest: async (id) => await dao.getChangeThresholdPercentRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeThresholdPercentAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeThresholdPercentRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeThresholdPercentRequest(id),

      approveRequest: async (id) => await dao.changeThresholdPercentRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeThresholdPercentRequest(+proposalData.thresholdPercent),
      proposalValidation: (value, setMessage) => {
        if (+value > 51 || !value) {
          setMessage('')
        } else {
          setMessage('The percentage must be greater than 51')
        }
      },
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Percent: ${Number(data?.thresholdPercent)}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Threshold Percent',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'thresholdPercent',
          formLink: 'thresholdPercent',
        },
      ],
    },
    {
      type: 'ChangeDAO',
      name: 'Change DAO',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeDAORequestsCounter(),
      getRequest: async (id) => await dao.getChangeDAORequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeDAOAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeDAORequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeDAORequest(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeDAORequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Address: ${data.dao}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Contract address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'address',
          formLink: 'address',
        },
      ],
    },
    {
      type: 'ChangeOwner',
      name: 'Change owner',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeOwnerRequestsCounter(),
      getRequest: async (id) => await dao.getChangeOwnerRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeOwnerAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeOwnerRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeOwnerRequest(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeOwnerRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Owner: ${data.owner}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'address',
          formLink: 'address',
        },
      ],
    },
    {
      type: 'UpgradeImplementation',
      name: 'Upgrade implementation',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getUpgradeImplementationRequestsCounter(),
      getRequest: async (id) => await dao.getUpgradeImplementationRequest(id),
      getRequestVotes: async (id) => await dao.countGetUpgradeImplementationAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForUpgradeImplementationRequest(type, id),
      cancelRequest: async (id) => await dao.cancelUpgradeImplementationRequest(id),
      createNewProposal: async (proposalData) =>
        await dao.newUpgradeImplementationRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Address: ${data.implementation}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Contract address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'address',
          formLink: 'address',
        },
      ],
    },
    {
      type: 'ChangeHotTreasury',
      name: 'Change hot treasury',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeHotTreasuryRequestsCounter(),
      getRequest: async (id) => await dao.getChangeHotTreasuryRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeHotTreasuryAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeHotTreasuryRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeHotTreasuryRequest(id),
      approveRequest: async (id) => await dao.changeHotTreasuryRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeHotTreasuryRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Address: ${data.treasury}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Contract address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'address',
          formLink: 'address',
        },
      ],
    },
    {
      type: 'RescueTransfer',
      name: 'Rescue transfer',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getRescueTransferRequestsCounter(),
      getRequest: async (id) => await dao.getRescueTransferRequest(id),
      getRequestVotes: async (id) => await dao.countGetRescueTransferAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForRescueTransferRequest(type, id),
      cancelRequest: async (id) => await dao.cancelRescueTransferRequest(id),
      approveRequest: async (id) => await dao.changeHotTreasuryRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newRescueTransferRequest(
          proposalData.token,
          proposalData.recipient,
          proposalData.amount,
        ),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: `Token: ${data.token}` },
            { label: `Recipient: ${data.recipient}` },
            { label: `Amount: ${Number(data.amount)}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Token Address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'token',
          formLink: 'token',
        },
        {
          name: 'Recipient address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'recipient',
          formLink: 'recipient',
        },
        {
          name: 'Amount',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'amount',
          formLink: 'amount',
        },
      ],
    },
  ]

  return mapDAO
}
