import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Liquidity, Settings } from '../components/common'
import { CustomTab } from '../components/ui'
import { FormConnectWallet } from '../components/forms'
import { useSupport } from '../hooks/useSupport'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { useMetaMask } from 'metamask-react'
import { availableChainIds, daoAddresses, providerUrls } from '../constant/chain/chain'
import { Contract, ethers } from 'ethers'
import DefaultAlert from '../components/common/DefaultAlert'
import { daoDepositStatuses } from '../constant/common/commonConst'
import { useDAO } from '../hooks/useDAO'
import { CircularLoader } from '../components/ui/CircularLoader'
import { daoRequestsSlice } from '../store/reducers/daoRequestsSlice'
import { find } from 'lodash'
import { NewProposal } from '../components/forms/NewProposal'
import { coldTreasuryAbi} from '../constant/deposit'
import usePagination from '../hooks/usePagination'
import { makeDaoColdTreasuryDepositObject } from '../utils/daoObjects/createDAOColdTreasuryDepositObject'
import { useDepositDAO } from '../hooks/useDepositDAO'

const DaoColdTreasuryDeposit: React.FC = () => {
  const dispatch = useAppDispatch()

  const { connectWallet } = useSupport()
  const { isConnect } = useAppSelector((state) => state.walletReducer)
  const { chainId } = useMetaMask()

  const provider = useMemo(
    () =>
      /* @ts-ignore */
      new ethers.providers.JsonRpcProvider(providerUrls[chainId], {
        chainId: parseInt(chainId, 16),
      }),
    [chainId],
  )

  const { setTreasuryNewData } = daoRequestsSlice.actions

  const [tableData, setTableData] = useState([])
  const [DAOMap, setDAOMap] = useState([])
  const [alert, setAlert] = useState(null)
  const [votersList, setVotersList] = useState([])
  const [openProgress, setOpenProgress] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [currentDao, setCurrentDao] = useState(null)
  const [addresses, setAddresses] = useState([])

  const { getCurrentVoters, cancelRequest, approveRequest, sendVote } = useDAO(provider)
  const { makeData } = useDepositDAO()

  const { currentPage, rowsPerPage, count, setCount, handlePageChange, handleRowsPerPageChange } =
    usePagination()

  const getData = async () => {
    try {
      setOpenProgress(true)
      const { signer } = await connectWallet()
      const coldTreasuryDAOAddress = daoAddresses[chainId]?.coldTreasuryDAOAddress

      if (coldTreasuryDAOAddress) {
        const coldTreasuryContract = new Contract(coldTreasuryDAOAddress, coldTreasuryAbi, signer)
        // const hotTreasuryContract = new Contract(hotTreasuryDAOAddress, hotTreasuryAbi, signer)
        // const smartWalletFactoryContract = new Contract(smartWalletFactoryDAOAddress, smartWalletFactoryAbi, signer)

        const daoActionMap = makeDaoColdTreasuryDepositObject(coldTreasuryContract)

        let selectedDao
        if (selectedType) {
          selectedDao = find(daoActionMap, { type: selectedType })
        } else {
          setSelectedType(daoActionMap[0].type)
          selectedDao = find(daoActionMap, { type: daoActionMap[0].type })
        }
        const { votersList, voterCount } = await getCurrentVoters(coldTreasuryContract)
        const { tableData, lastId } = await makeData(
          selectedDao,
          voterCount,
          signer,
          rowsPerPage,
          currentPage,
        )
        setCount(lastId)

        dispatch(setTreasuryNewData({ requests: tableData, voters: votersList }))
        setAddresses([
          { name: `DAO Address: ${coldTreasuryDAOAddress}` },
        ])
        setCurrentDao(selectedDao)
        setDAOMap(daoActionMap)
        setVotersList(votersList)
        setTableData(tableData)
      }
    } catch (err) {
      console.log(err)
      setTableData([])
      setVotersList([])
      setAlert({ open: true, type: 'error', text: 'something went wrong' })
    } finally {
      setOpenProgress(false)
    }
  }

  const makeVote = async (type, id, voteType) => {
    await sendVote(currentDao, type, id, setAlert, voteType)
  }

  const handleCancelRequest = async (type, id) => {
    await cancelRequest(currentDao, id, setAlert)
  }

  const handleApproveRequest = async (type, id) => {
    await approveRequest(currentDao, id, setAlert)
  }

  useEffect(() => {
    if (isConnect && availableChainIds.includes(chainId)) {
      setOpenProgress(true)
      getData()
    } else {
      setTableData([])
    }
  }, [isConnect, chainId, selectedType, currentPage, rowsPerPage])

  return (
    <>
      <CircularLoader open={openProgress} />
      <DefaultAlert
        open={alert?.open}
        type={alert?.type}
        text={alert?.text}
        onClose={() => setAlert(null)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant='h4' sx={{ px: 2, mb: 4 }}>
            Cold treasury deposit DAO
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormConnectWallet onChainChangeTrigger={handlePageChange} />
        </Grid>
      </Grid>
      <CustomTab
        items={[
          {
            label: 'Settings',
            component: (
              <Settings
                addresses={addresses}
                getData={getData}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                DAOMap={DAOMap}
                statuses={daoDepositStatuses}
                tableData={tableData}
                makeVote={makeVote}
                handleCancelRequest={handleCancelRequest}
                handleApproveRequest={handleApproveRequest}
                votersList={votersList}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                count={count}
                onPageChange={handlePageChange}
                onRowCountChange={handleRowsPerPageChange}
              />
            ),
          },
          {
            label: 'Liquidity',
            component: <Liquidity />,
          },
        ]}
      />
      {DAOMap.length && <NewProposal daoMap={DAOMap} setAlert={setAlert} />}
    </>
  )
}

export default DaoColdTreasuryDeposit
