export const bridgeAbiNoFee = [
  {
    'inputs': [
      {
        'internalType': 'uint8',
        'name': 'domainID',
        'type': 'uint8'
      },
      {
        'internalType': 'address[]',
        'name': 'initialRelayers',
        'type': 'address[]'
      },
      {
        'internalType': 'uint256',
        'name': 'initialRelayerThreshold',
        'type': 'uint256'
      },
      {
        'internalType': 'uint256',
        'name': 'expiry',
        'type': 'uint256'
      },
      {
        'internalType': 'uint256',
        'name': '_feeMaxValue',
        'type': 'uint256'
      },
      {
        'internalType': 'uint256',
        'name': '_feePercent',
        'type': 'uint256'
      }
    ],
    'stateMutability': 'nonpayable',
    'type': 'constructor'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'uint8',
        'name': 'destinationDomainID',
        'type': 'uint8'
      },
      {
        'indexed': false,
        'internalType': 'bytes32',
        'name': 'resourceID',
        'type': 'bytes32'
      },
      {
        'indexed': false,
        'internalType': 'uint64',
        'name': 'depositNonce',
        'type': 'uint64'
      },
      {
        'indexed': true,
        'internalType': 'address',
        'name': 'user',
        'type': 'address'
      },
      {
        'indexed': false,
        'internalType': 'bytes',
        'name': 'data',
        'type': 'bytes'
      },
      {
        'indexed': false,
        'internalType': 'bytes',
        'name': 'handlerResponse',
        'type': 'bytes'
      }
    ],
    'name': 'Deposit',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'bytes',
        'name': 'lowLevelData',
        'type': 'bytes'
      }
    ],
    'name': 'FailedHandlerExecution',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      }
    ],
    'name': 'Paused',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'uint8',
        'name': 'originDomainID',
        'type': 'uint8'
      },
      {
        'indexed': false,
        'internalType': 'uint64',
        'name': 'depositNonce',
        'type': 'uint64'
      },
      {
        'indexed': false,
        'internalType': 'enum Bridge.ProposalStatus',
        'name': 'status',
        'type': 'uint8'
      },
      {
        'indexed': false,
        'internalType': 'bytes32',
        'name': 'dataHash',
        'type': 'bytes32'
      }
    ],
    'name': 'ProposalEvent',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'uint8',
        'name': 'originDomainID',
        'type': 'uint8'
      },
      {
        'indexed': false,
        'internalType': 'uint64',
        'name': 'depositNonce',
        'type': 'uint64'
      },
      {
        'indexed': false,
        'internalType': 'enum Bridge.ProposalStatus',
        'name': 'status',
        'type': 'uint8'
      },
      {
        'indexed': false,
        'internalType': 'bytes32',
        'name': 'dataHash',
        'type': 'bytes32'
      }
    ],
    'name': 'ProposalVote',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'address',
        'name': 'relayer',
        'type': 'address'
      }
    ],
    'name': 'RelayerAdded',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'address',
        'name': 'relayer',
        'type': 'address'
      }
    ],
    'name': 'RelayerRemoved',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'uint256',
        'name': 'newThreshold',
        'type': 'uint256'
      }
    ],
    'name': 'RelayerThresholdChanged',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': true,
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      },
      {
        'indexed': true,
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      },
      {
        'indexed': true,
        'internalType': 'address',
        'name': 'sender',
        'type': 'address'
      }
    ],
    'name': 'RoleGranted',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': true,
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      },
      {
        'indexed': true,
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      },
      {
        'indexed': true,
        'internalType': 'address',
        'name': 'sender',
        'type': 'address'
      }
    ],
    'name': 'RoleRevoked',
    'type': 'event'
  },
  {
    'anonymous': false,
    'inputs': [
      {
        'indexed': false,
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      }
    ],
    'name': 'Unpaused',
    'type': 'event'
  },
  {
    'inputs': [],
    'name': 'DEFAULT_ADMIN_ROLE',
    'outputs': [
      {
        'internalType': 'bytes32',
        'name': '',
        'type': 'bytes32'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': 'MAX_RELAYERS',
    'outputs': [
      {
        'internalType': 'uint256',
        'name': '',
        'type': 'uint256'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': 'RELAYER_ROLE',
    'outputs': [
      {
        'internalType': 'bytes32',
        'name': '',
        'type': 'bytes32'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint8',
        'name': '',
        'type': 'uint8'
      }
    ],
    'name': '_depositCounts',
    'outputs': [
      {
        'internalType': 'uint64',
        'name': '',
        'type': 'uint64'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': '_domainID',
    'outputs': [
      {
        'internalType': 'uint8',
        'name': '',
        'type': 'uint8'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': '_expiry',
    'outputs': [
      {
        'internalType': 'uint40',
        'name': '',
        'type': 'uint40'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint72',
        'name': 'destNonce',
        'type': 'uint72'
      },
      {
        'internalType': 'bytes32',
        'name': 'dataHash',
        'type': 'bytes32'
      },
      {
        'internalType': 'address',
        'name': 'relayer',
        'type': 'address'
      }
    ],
    'name': '_hasVotedOnProposal',
    'outputs': [
      {
        'internalType': 'bool',
        'name': '',
        'type': 'bool'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': '_relayerThreshold',
    'outputs': [
      {
        'internalType': 'uint8',
        'name': '',
        'type': 'uint8'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': '',
        'type': 'bytes32'
      }
    ],
    'name': '_resourceIDToHandlerAddress',
    'outputs': [
      {
        'internalType': 'address',
        'name': '',
        'type': 'address'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': '_totalRelayers',
    'outputs': [
      {
        'internalType': 'uint256',
        'name': '',
        'type': 'uint256'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'address',
        'name': 'relayerAddress',
        'type': 'address'
      }
    ],
    'name': 'adminAddRelayer',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminChangeFee',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminChangeFeePercent',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminChangeRelayerThreshold',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminPauseStatusTransfers',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'address',
        'name': 'relayerAddress',
        'type': 'address'
      }
    ],
    'name': 'adminRemoveRelayer',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminSetBurnable',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminSetDepositNonce',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminSetForwarder',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminSetGenericResource',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminSetResource',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'adminWithdraw',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint8',
        'name': 'domainID',
        'type': 'uint8'
      },
      {
        'internalType': 'uint64',
        'name': 'depositNonce',
        'type': 'uint64'
      },
      {
        'internalType': 'bytes32',
        'name': 'dataHash',
        'type': 'bytes32'
      }
    ],
    'name': 'cancelProposal',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint8',
        'name': 'destinationDomainID',
        'type': 'uint8'
      },
      {
        'internalType': 'bytes32',
        'name': 'resourceID',
        'type': 'bytes32'
      },
      {
        'internalType': 'bytes',
        'name': 'data',
        'type': 'bytes'
      }
    ],
    'name': 'deposit',
    'outputs': [],
    'stateMutability': 'payable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint8',
        'name': 'destinationDomainID',
        'type': 'uint8'
      },
      {
        'internalType': 'uint8',
        'name': 'domainID',
        'type': 'uint8'
      },
      {
        'internalType': 'uint64',
        'name': 'depositNonce',
        'type': 'uint64'
      },
      {
        'internalType': 'bytes',
        'name': 'data',
        'type': 'bytes'
      },
      {
        'internalType': 'bytes32',
        'name': 'resourceID',
        'type': 'bytes32'
      },
      {
        'internalType': 'bool',
        'name': 'revertOnFail',
        'type': 'bool'
      }
    ],
    'name': 'executeProposal',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': 'getContractDAO',
    'outputs': [
      {
        'internalType': 'contract IDAO',
        'name': '',
        'type': 'address'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'address',
        'name': 'tokenAddress',
        'type': 'address'
      },
      {
        'internalType': 'uint8',
        'name': 'chainId',
        'type': 'uint8'
      }
    ],
    'name': 'getFee',
    'outputs': [
      {
        'internalType': 'uint256',
        'name': '',
        'type': 'uint256'
      },
      {
        'internalType': 'uint256',
        'name': '',
        'type': 'uint256'
      },
      {
        'internalType': 'uint256',
        'name': '',
        'type': 'uint256'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': 'getFeeMaxValue',
    'outputs': [
      {
        'internalType': 'uint128',
        'name': '',
        'type': 'uint128'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': 'getFeePercent',
    'outputs': [
      {
        'internalType': 'uint64',
        'name': '',
        'type': 'uint64'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint8',
        'name': 'originDomainID',
        'type': 'uint8'
      },
      {
        'internalType': 'uint64',
        'name': 'depositNonce',
        'type': 'uint64'
      },
      {
        'internalType': 'bytes32',
        'name': 'dataHash',
        'type': 'bytes32'
      }
    ],
    'name': 'getProposal',
    'outputs': [
      {
        'components': [
          {
            'internalType': 'enum Bridge.ProposalStatus',
            'name': '_status',
            'type': 'uint8'
          },
          {
            'internalType': 'uint200',
            'name': '_yesVotes',
            'type': 'uint200'
          },
          {
            'internalType': 'uint8',
            'name': '_yesVotesTotal',
            'type': 'uint8'
          },
          {
            'internalType': 'uint40',
            'name': '_proposedBlock',
            'type': 'uint40'
          }
        ],
        'internalType': 'struct Bridge.Proposal',
        'name': '',
        'type': 'tuple'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      }
    ],
    'name': 'getRoleAdmin',
    'outputs': [
      {
        'internalType': 'bytes32',
        'name': '',
        'type': 'bytes32'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      },
      {
        'internalType': 'uint256',
        'name': 'index',
        'type': 'uint256'
      }
    ],
    'name': 'getRoleMember',
    'outputs': [
      {
        'internalType': 'address',
        'name': '',
        'type': 'address'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      }
    ],
    'name': 'getRoleMemberCount',
    'outputs': [
      {
        'internalType': 'uint256',
        'name': '',
        'type': 'uint256'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      },
      {
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      }
    ],
    'name': 'getRoleMemberIndex',
    'outputs': [
      {
        'internalType': 'uint256',
        'name': '',
        'type': 'uint256'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      },
      {
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      }
    ],
    'name': 'grantRole',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      },
      {
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      }
    ],
    'name': 'hasRole',
    'outputs': [
      {
        'internalType': 'bool',
        'name': '',
        'type': 'bool'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'address',
        'name': 'relayer',
        'type': 'address'
      }
    ],
    'name': 'isRelayer',
    'outputs': [
      {
        'internalType': 'bool',
        'name': '',
        'type': 'bool'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'address',
        'name': '',
        'type': 'address'
      }
    ],
    'name': 'isValidForwarder',
    'outputs': [
      {
        'internalType': 'bool',
        'name': '',
        'type': 'bool'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [],
    'name': 'paused',
    'outputs': [
      {
        'internalType': 'bool',
        'name': '',
        'type': 'bool'
      }
    ],
    'stateMutability': 'view',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'renounceAdmin',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      },
      {
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      }
    ],
    'name': 'renounceRole',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'bytes32',
        'name': 'role',
        'type': 'bytes32'
      },
      {
        'internalType': 'address',
        'name': 'account',
        'type': 'address'
      }
    ],
    'name': 'revokeRole',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'address',
        'name': '_address',
        'type': 'address'
      }
    ],
    'name': 'setDAOContractInitial',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint256',
        'name': 'id',
        'type': 'uint256'
      }
    ],
    'name': 'transferFunds',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  },
  {
    'inputs': [
      {
        'internalType': 'uint8',
        'name': 'destinationDomainID',
        'type': 'uint8'
      },
      {
        'internalType': 'uint8',
        'name': 'domainID',
        'type': 'uint8'
      },
      {
        'internalType': 'uint64',
        'name': 'depositNonce',
        'type': 'uint64'
      },
      {
        'internalType': 'bytes32',
        'name': 'resourceID',
        'type': 'bytes32'
      },
      {
        'internalType': 'bytes',
        'name': 'data',
        'type': 'bytes'
      }
    ],
    'name': 'voteProposal',
    'outputs': [],
    'stateMutability': 'nonpayable',
    'type': 'function'
  }
]