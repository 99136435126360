import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { FormConnectWallet } from '../components/forms'
import { CustomTab } from '../components/ui'
import { Liquidity, Settings } from '../components/common'
import { useSupport } from '../hooks/useSupport'
import { Contract, ethers } from 'ethers'
import { find } from 'lodash'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import DefaultAlert from '../components/common/DefaultAlert'
import { useMetaMask } from 'metamask-react'
import { availableChainIds, daoAddresses, providerUrls } from '../constant/chain/chain'
import { makeDAOUniswapObj } from '../utils/daoObjects/createDAOUniswapObject'
import { useDAO } from '../hooks/useDAO'
import { DAOUniswapAbi } from '../constant/uniswap/DAOUniswapAbi'
import { uniswapFactoryAbi } from '../constant/uniswap/uniswapFactoryAbi'
import { daoStatuses } from '../constant/common/commonConst'
import { CircularLoader } from '../components/ui/CircularLoader'
import { daoRequestsSlice } from '../store/reducers/daoRequestsSlice'
import { NewProposal } from '../components/forms/NewProposal'
import usePagination from '../hooks/usePagination'

const DaoUniswap: React.FC = () => {
  const dispatch = useAppDispatch()

  const {connectWallet} = useSupport()
  const {isConnect} = useAppSelector(state => state.walletReducer)
  const {chainId} = useMetaMask()
// @ts-ignore
  const provider = useMemo(() => new ethers.providers.JsonRpcProvider(providerUrls[chainId], {
    chainId: parseInt(chainId, 16)
  }), [chainId])

  const {setUniswapData} = daoRequestsSlice.actions

  const [tableData, setTableData] = useState([])
  const [DAOMap, setDAOMap] = useState([])
  const [alert, setAlert] = useState(null)
  const [votersList, setVotersList] = useState([])
  const [openProgress, setOpenProgress] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [currentDao, setCurrentDao] = useState(null)
  const [addresses, setAddresses] = useState([])

  // @ts-ignore
  const {makeDataForNewDao, getCurrentVoters, cancelRequest, approveRequest} = useDAO(provider)
  const {currentPage, rowsPerPage, count, setCount, handlePageChange, handleRowsPerPageChange} = usePagination()

  const getData = async () => {
    try {
      setOpenProgress(true)
      const {signer} = await connectWallet()
      const DAOUniswapAddress = daoAddresses[chainId].DAOUniswapAddress
      const uniswapFactoryAddress = daoAddresses[chainId].uniswapFactoryAddress

      const DAO = new Contract(DAOUniswapAddress, DAOUniswapAbi, signer)
      const factory = new Contract(uniswapFactoryAddress, uniswapFactoryAbi, signer)

      const daoActionMap = makeDAOUniswapObj(DAO, factory, DAOUniswapAddress, provider)
      let selectedDao
      if(selectedType){
        selectedDao = find(daoActionMap, {type: selectedType})
      } else {
        setSelectedType(daoActionMap[0].type)
        selectedDao = find(daoActionMap, {type: daoActionMap[0].type})
      }

      const { votersList, voterCount } = await getCurrentVoters(DAO)
      const { tableData, lastId } = await makeDataForNewDao(selectedDao, voterCount, signer, rowsPerPage, currentPage);
      setCount(lastId)

      dispatch(setUniswapData({requests: tableData, voters: votersList }))
      setAddresses([
        {name: `DAO Address: ${DAOUniswapAddress}`},
        {name: `Uniswap Factory Address: ${uniswapFactoryAddress}`},
      ])
      setCurrentDao(selectedDao)
      setDAOMap(daoActionMap)
      setVotersList(votersList)
      setTableData(tableData)

    }catch (err) {
      console.log(err)
      setTableData([])
      setVotersList([])
      setAlert({open: true, type: 'error', text: 'something went wrong'})
    } finally {
      setOpenProgress(false)
    }
  }

  const makeVote = async (type, id, voteType) => {
    const {signer, signerAddress} = await connectWallet()
    const DAOUniswapAddress = daoAddresses[chainId].DAOUniswapAddress
    const DAO = new Contract(DAOUniswapAddress, DAOUniswapAbi, signer)
    const canVote = await DAO.getVoterStatusByAddress(signerAddress)
    if(canVote){
      // @ts-ignore
      try {
        // @ts-ignore
        await currentDao.makeVote(voteType, id)
      }catch (err) {
        console.log(err)
      }

    } else {
      setAlert({open: true, type: 'warning', text: 'you can\'t vote'})
    }
  }

  const handleApproveRequest = async (type, id) => {
    await approveRequest(currentDao, id, setAlert)
  }

  const handleCancelRequest = async (type, id) => {
    await cancelRequest(currentDao, id, setAlert)
  }

  useEffect(() => {
    if(isConnect && availableChainIds.includes(chainId)){
      setOpenProgress(true)
      getData()
    } else {
      setTableData([])
    }
  }, [isConnect, chainId, selectedType, currentPage, rowsPerPage])

  // @ts-ignore
  return <>
    <CircularLoader
      open={openProgress}
    />
    <DefaultAlert
      open={alert?.open}
      type={alert?.type}
      text={alert?.text}
      onClose={() => setAlert(null)}
    />
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography variant='h4' sx={{ px: 2, mb: 4 }}>
          Uniswap DAO
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormConnectWallet onChainChangeTrigger={handlePageChange}/>
      </Grid>
    </Grid>
    <CustomTab
      items={[
        {
          label: 'Settings',
          component:
            <Settings
              addresses={addresses}
              getData={getData}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              DAOMap={DAOMap}
              statuses={daoStatuses}
              tableData={tableData}
              makeVote={makeVote}
              handleCancelRequest={handleCancelRequest}
              handleApproveRequest={handleApproveRequest}
              votersList={votersList}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              count={count}
              onPageChange={handlePageChange}
              onRowCountChange={handleRowsPerPageChange}
            />,
        },
        {
          label: 'Liquidity',
          component: <Liquidity />,
        },
      ]}
    />
    {DAOMap.length &&
      <NewProposal
        daoMap={DAOMap}
      />
    }
  </>
}

export default DaoUniswap
