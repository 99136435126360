export const makeDaoHotTreasuryDepositObject = (dao) => {
  const mapDAO = [
    {
      type: 'voterRequest',
      name: 'Voter',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getVoterRequestsCounter(),
      getRequest: async (id) => await dao.getVoterRequest(id),
      getRequestVotes: async (id) => await dao.countGetVotersAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForVoterRequest(type, id),
      cancelRequest: async (id) => await dao.cancelVoterRequest(id),
      approveRequest: async (id) => await dao.votersRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newVoterRequest(!!proposalData.include, proposalData.voterAddress),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: data.include ? 'Add voter' : 'Remove voter' },
            { label: `Address: ${data.candidate}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Include?',
          xs: 2,
          type: 'boolean',
          defaultValue: 1,
          daoName: 'tokenAddress',
          formLink: 'include',
        },
        {
          name: 'Voter Address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'tokenAddress',
          formLink: 'voterAddress',
        },
      ],
    },
    {
      type: 'ChangeAdmin',
      name: 'Change admin',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getAdminRequestsCounter(),
      getRequest: async (id) => await dao.getAdminRequest(id),
      getRequestVotes: async (id) => await dao.countGetAdminsAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForAdminRequest(type, id),
      cancelRequest: async (id) => await dao.cancelAdminRequest(id),

      approveRequest: async (id) => await dao.adminsRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newAdminRequest(!!proposalData.include, proposalData.adminAddress),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: data.include ? 'Add admin' : 'Remove admin' },
            { label: `Address: ${data.candidate}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Include?',
          xs: 2,
          type: 'boolean',
          defaultValue: 1,
          daoName: 'tokenAddress',
          formLink: 'include',
        },
        {
          name: 'Admin Address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'adminAddress',
          formLink: 'adminAddress',
        },
      ],
    },
    {
      type: 'ChangeThresholdPercent',
      name: 'Change threshold percent',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeThresholdPercentRequestsCounter(),
      getRequest: async (id) => await dao.getChangeThresholdPercentRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeThresholdPercentAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeThresholdPercentRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeThresholdPercentRequest(id),

      approveRequest: async (id) => await dao.changeThresholdPercentRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeThresholdPercentRequest(+proposalData.thresholdPercent),
      proposalValidation: (value, setMessage) => {
        if (+value > 51 || !value) {
          setMessage('')
        } else {
          setMessage('The percentage must be greater than 51')
        }
      },
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Percent: ${Number(data?.thresholdPercent)}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Threshold Percent',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'thresholdPercent',
          formLink: 'thresholdPercent',
        },
      ],
    },
    {
      type: 'ChangeDAO',
      name: 'Change DAO',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeDAORequestsCounter(),
      getRequest: async (id) => await dao.getChangeDAORequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeDAOAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeDAORequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeDAORequest(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeDAORequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Address: ${data.dao}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Contract address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'address',
          formLink: 'address',
        },
      ],
    },
    {
      type: 'ChangeOwner',
      name: 'Change owner',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeOwnerRequestsCounter(),
      getRequest: async (id) => await dao.getChangeOwnerRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeOwnerAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeOwnerRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeOwnerRequest(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeOwnerRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Owner: ${data.owner}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'address',
          formLink: 'address',
        },
      ],
    },
    {
      type: 'UpgradeImplementation',
      name: 'Upgrade implementation',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getUpgradeImplementationRequestsCounter(),
      getRequest: async (id) => await dao.getUpgradeImplementationRequest(id),
      getRequestVotes: async (id) => await dao.countGetUpgradeImplementationAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForUpgradeImplementationRequest(type, id),
      cancelRequest: async (id) => await dao.cancelUpgradeImplementationRequest(id),
      createNewProposal: async (proposalData) =>
        await dao.newUpgradeImplementationRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Address: ${data.implementation}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Contract address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'address',
          formLink: 'address',
        },
      ],
    },
    {
      type: 'ChangeColdTreasury',
      name: 'Change cold treasury',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeColdTreasuryRequestsCounter(),
      getRequest: async (id) => await dao.getChangeColdTreasuryRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeColdTreasuryAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeColdTreasuryRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeColdTreasuryRequest(id),
      approveRequest: async (id) => await dao.changeColdTreasuryRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeColdTreasuryRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [{ label: `Address: ${data.treasury}` }],
        }
      },
      newProposalSchema: [
        {
          name: 'Contract address',
          xs: 4,
          type: 'text',
          defaultValue: '',
          daoName: 'address',
          formLink: 'address',
        },
      ],
    },
    {
      type: 'RescueTransfer',
      name: 'Rescue transfer',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getRescueTransferRequestsCounter(),
      getRequest: async (id) => await dao.getRescueTransferRequest(id),
      getRequestVotes: async (id) => await dao.countGetRescueTransferAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForRescueTransferRequest(type, id),
      cancelRequest: async (id) => await dao.cancelRescueTransferRequest(id),
      approveRequest: async (id) => await dao.changeHotTreasuryRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newRescueTransferRequest(
          proposalData.token,
          proposalData.recipient,
          proposalData.amount,
        ),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: `Token: ${data.token}` },
            { label: `Recipient: ${data.recipient}` },
            { label: `Amount: ${Number(data.amount)}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Token Address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'token',
          formLink: 'token',
        },
        {
          name: 'Recipient address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'recipient',
          formLink: 'recipient',
        },
        {
          name: 'Amount',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'amount',
          formLink: 'amount',
        },
      ],
    },
    {
      type: 'ChangeCommissionAddress',
      name: 'Change commission address',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeCommissionAddressRequestsCounter(),
      getRequest: async (id) => await dao.getChangeCommissionAddressRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeCommissionAddressAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeCommissionAddressRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeCommissionAddressRequest(id),
      approveRequest: async (id) => await dao.changeCommissionAddressRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeCommissionAddressRequest(
          proposalData.commissionAddress,
        ),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: `Address: ${data.commissionAddress}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Commission address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'commissionAddress',
          formLink: 'commissionAddress',
        },
      
      ],
    },
    {
      type: 'ChangeHardwareWallet',
      name: 'Change hardware wallet',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeHardwareWalletRequestsCounter(),
      getRequest: async (id) => await dao.getChangeHardwareWalletRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeHardwareWalletAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeHardwareWalletRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeHardwareWalletRequest(id),
      approveRequest: async (id) => await dao.changeHardwareWalletRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeHardwareWalletRequest(
          proposalData.hardwareWallet,
        ),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: `Address: ${data.hardwareWallet}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Hardware wallet address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'hardwareWallet',
          formLink: 'hardwareWallet',
        },
      
      ],
    },
    {
      type: 'ChangeTotalLimitPerDay',
      name: 'Change total limit per day',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeTotalLimitPerDayRequestsCounter(),
      getRequest: async (id) => await dao.getChangeTotalLimitPerDayRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeTotalLimitPerDayAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeTotalLimitPerDayRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeTotalLimitPerDayRequest(id),
      approveRequest: async (id) => await dao.changeTotalLimitPerDayRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeTotalLimitPerDayRequest(
          proposalData.tokenAddress,
          proposalData.amount,
        ),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: `Token address: ${data.token}` },
            { label: `Amount: ${data.amount}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Token Address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'tokenAddress',
          formLink: 'tokenAddress',
        },
        {
          name: 'Amount',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'amount',
          formLink: 'amount',
        },
      ],
    },
    {
      type: 'ChangeTotalLimitPerDayPerUser',
      name: 'Change total limit per day per user',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getChangeTotalLimitPerDayPerUserRequestsCounter(),
      getRequest: async (id) => await dao.getChangeTotalLimitPerDayPerUserRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeTotalLimitPerDayPerUserAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForChangeTotalLimitPerDayPerUserRequest(type, id),
      cancelRequest: async (id) => await dao.cancelChangeTotalLimitPerDayPerUserRequest(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeTotalLimitPerDayPerUserRequest(
          proposalData.tokenAddress,
          proposalData.amount,
        ),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: `Token address: ${data.token}` },
            { label: `Amount: ${data.amount}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Token Address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'tokenAddress',
          formLink: 'tokenAddress',
        },
        {
          name: 'Amount',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'amount',
          formLink: 'amount',
        },
      ],
    },
    {
      type: 'OverrideTotalLimitPerDay',
      name: 'Override total limit per day',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getOverrideTotalLimitPerDayRequestsCounter(),
      getRequest: async (id) => await dao.getOverrideTotalLimitPerDayRequest(id),
      getRequestVotes: async (id) => await dao.countGetOverrideTotalLimitPerDayAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForOverrideTotalLimitPerDayRequest(type, id),
      cancelRequest: async (id) => await dao.cancelOverrideTotalLimitPerDayRequest(id),
      approveRequest: async (id) => await dao.overrideTotalLimitPerDayRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newOverrideTotalLimitPerDayRequest(
          proposalData.tokenAddress,
          proposalData.epoch,
          proposalData.amount,
        ),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: `Token address: ${data.token}` },
            { label: `Epoch: ${data.epoch}` },
            { label: `Amount: ${data.amount}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Token Address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'tokenAddress',
          formLink: 'tokenAddress',
        },
        {
          name: 'Epoch',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'epoch',
          formLink: 'epoch',
        },
        {
          name: 'Amount',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'amount',
          formLink: 'amount',
        },
      ],
    },
    {
      type: 'OverrideTotalLimitPerDayPerUser',
      name: 'Override total limit per day per user',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getOverrideTotalLimitPerDayPerUserRequestsCounter(),
      getRequest: async (id) => await dao.getOverrideTotalLimitPerDayPerUserRequest(id),
      getRequestVotes: async (id) => await dao.countGetOverrideTotalLimitPerDayPerUserAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForOverrideTotalLimitPerDayPerUserRequest(type, id),
      cancelRequest: async (id) => await dao.cancelOverrideTotalLimitPerDayPerUserRequest(id),
      approveRequest: async (id) => await dao.overrideTotalLimitPerDayPerUserRequestConclusion(id),
      createNewProposal: async (proposalData) =>
        await dao.newOverrideTotalLimitPerDayPerUserRequest(
          proposalData.tokenAddress,
          proposalData.userAddress,
          proposalData.epoch,
          proposalData.amount,
        ),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            { label: `Token address: ${data.token}` },
            { label: `User address: ${data.user}` },
            { label: `Epoch: ${data.epoch}` },
            { label: `Amount: ${data.amount}` },
          ],
        }
      },
      newProposalSchema: [
        {
          name: 'Token Address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'tokenAddress',
          formLink: 'tokenAddress',
        },
        {
          name: 'User Address',
          xs: 3,
          type: 'text',
          defaultValue: '',
          daoName: 'userAddress',
          formLink: 'userAddress',
        },
        {
          name: 'Epoch',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'epoch',
          formLink: 'epoch',
        },
        {
          name: 'Amount',
          xs: 2,
          type: 'text',
          defaultValue: '',
          daoName: 'amount',
          formLink: 'amount',
        },
      ],
    },
  ]

  return mapDAO
}
