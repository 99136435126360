import { config } from '../../app-config';

const {
  ethereumChainId,
  bscChainId,
  avalancheChainId,
  polygonChainId,
  fantomChainId,
  bscTestnetChainId,
  baseChainId
} = config

export const fantomTestNetId = '0xfa2'

export const ultronChainId = '0x4cf';
export const ultronChainIdTestNet = '0x4ce';

export const ultronRpcUrl = 'https://ultron-rpc.net';
export const ultronRpcUrlTestNet = 'https://ultron-dev.io';

export const ultronChainName = 'Ultron';
export const ultronChainNameTestNet = 'Ultron(T)';

export const ultronExplorerUrl = 'https://ulxscan.com/';
export const ultronExplorerUrlTestNet = 'https://explorer.ultron-dev.io/';

export const providerUrls = {
  [ultronChainId]: ultronRpcUrl,
  [ultronChainIdTestNet]: ultronRpcUrlTestNet,
  [ethereumChainId]: 'https://eth-mainnet.alchemyapi.io/v2/yTaJRZrkn9LUEI6S7_GQhU4_9fhMgMsv',
  [bscChainId]: 'https://rpc.ankr.com/bsc',
  [avalancheChainId]: 'https://api.avax.network/ext/bc/C/rpc',
  [polygonChainId]: 'https://polygon-mainnet.g.alchemy.com/v2/1NyLrZDbQlwOlSdSWBYYcRpioLBnvBeX',
  [fantomChainId]: 'https://rpc.ftm.tools',
  [fantomTestNetId]: 'https://rpc.testnet.fantom.network',
  [bscTestnetChainId]: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  [baseChainId]: ['https://base-rpc.publicnode.com']
}

export const rpcUrls = {
  [ultronChainId]: [ultronRpcUrl],
  [ultronChainIdTestNet]: [ultronRpcUrlTestNet],
  [ethereumChainId]: ['https://rpc.ankr.com/eth', 'https://mainnet-nethermind.blockscout.com/', 'https://eth-rpc.gateway.pokt.network'],
  [bscChainId]: ['https://bscrpc.com', 'https://rpc.ankr.com/bsc'],
  [avalancheChainId]: ['https://rpc.ankr.com/avalanche', 'https://avalanche.public-rpc.com'],
  [polygonChainId]: ['https://polygon-rpc.com', 'https://rpc.ankr.com/polygon', 'https://poly-rpc.gateway.pokt.network/'],
  [fantomChainId]: ['https://rpc.ankr.com/fantom', 'https://rpc.ftm.tools/'],
  [bscTestnetChainId]: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  [baseChainId]: ['https://base-rpc.publicnode.com']
}

export const daoAddresses = {
  [ultronChainId]: {
    'DAOTreasuryAddress': '0x9dfe15157D8ca9055ea811789cc8d331fefe89dA',
    'treasuryAddress': '0xD60e1D7CCf2Bb8E2052079914c333c92D687B965',
    'DAOTreasuryAddressOld': '0xa8F6cF203b36a674405eD1a065818F20F519Ad9b',
    'treasuryAddressOld': '0xbfBB549c0a5a628140dB28DE434E4D7a5a6d3Ca2',
    'DAOUniswapAddress': '0xa196e8E3F8dfBCe1a0BA03eEeE7CE717A584eFF5',
    'uniswapFactoryAddress': '0xe1F0D4a5123Fd0834Be805d84520DFDCd8CF00b7',
    'DAOWulxAddress': '0x44f14aeA634535291cFa0DC27E1D02a5B1358b3A',
    'wulxAddress': '0x3a4F06431457de873B588846d139EC0d86275d54',
    'DAOManageAddress': '0x8bB172c8bF04b0Afb699AeaDC479f409c3fc82eD',
    'manageAddress': '',
    'manageProxyAddress': '',
    'DAOPackagesAddress': '0xa8F6cF203b36a674405eD1a065818F20F519Ad9b',
    'packagesAddress': '',
    'DAOBridgeAddress': '0x6025adaD5b1EAC55f24e3e4783E0e881428017e8',
    'bridgeAddress': '0x61488630B3337b9b897eF3A0AB47CB180399CEa3',
    'erc20handlerAddress': '0xc078626DA5C09DC63A7c5C0c030f431EFfF098b8',

    'DAORevenueShareAddress': '0x76e1f2BEAe0C2c49E6C6E891cEe2C376EB9958E4',
    'revenueShareAddress': '0xF3A7E5546AC1214bE5127c662002a0389abC9B3C'
  },
    [ultronChainIdTestNet]: {
      'DAOTreasuryAddress': '0x47C2871B46555850E1a1c0963C22C699bFDb80Cd',
      'treasuryAddress': '0xc3898DD94E55eB352bd9a12eec7244288DC915Bf',
      'DAOTreasuryAddressOld': '0xc078626DA5C09DC63A7c5C0c030f431EFfF098b8',
      'treasuryAddressOld': '0x61488630B3337b9b897eF3A0AB47CB180399CEa3',
      'DAOUniswapAddress': '0xB57d8553334f4360dBad4975669C6E3cBf1317Ed',
      'uniswapFactoryAddress': '0xbaf935ad5af4d249438f786316b93D77ca90aDb7',
      'DAOWulxAddress': '0x2a79d76678d5e4aA1D62b87CeCDfbe2Bd5d872DF',
      'wulxAddress': '0x6025adaD5b1EAC55f24e3e4783E0e881428017e8',
      'DAOManageAddress': '0x2D48CeFa4fd8Efa58aB494048d7f87Ab8AC84Ba8',
      'manageAddress': '0xee132A7873b484C9153D5cda15CB37Efd01ba1aE',
      'manageProxyAddress': '0xfe46A6bE74106098C889F052337e5C01d1d7E763',
      'DAOPackagesAddress': '0xF3b1f35dcc343259EE954a7713C16EfF6Ae76e34',
      'packagesAddress': '0x966012EBDB768536a9580A909a646d5812C981AB',
      'DAOBridgeAddress': '0xFE75cE9A41C25fD19d3dCE833dE29eB59bC8b1e4',
      'bridgeAddress': '0x263B7AD822Cc82E5dEaF883eac62588C08623822',
      'erc20handlerAddress': '0x183Bce362fD6a70DEbE302a42b495CA6cf744F8e',

      'DAORevenueShareAddress': '0xc56C05e8fbE4CE9C07764f19643e6F0486CcdAC6',
      'revenueShareAddress': '0x21E4f2E73292b474e8f7A2D4A10419D619220b92',
      'coldTreasuryDAOAddress': '0xC90e9A7454Df1F047813e1D3aef05418613e6fC3',
      'hotTreasuryDAOAddress': '0x993d6c357A70cb816456EFcAc5FDe58c834239F3',
      'smartWalletFactoryDAOAddress': '0xfDd46e6b578Ea8ad582776E2AbBdaD5eD2667774'
    },
    // fantom test net
    [fantomTestNetId]: {
      'DAOUniswapAddress': '0xD0d0B25dBCA9488F409f2f5e8BE92B60CE4e01Ab',
      'uniswapFactoryAddress': '0x5024D1E290523c68f09816f847E6fac5B5eeaf28',
      'DAOBridgeAddress': '0x792C62201167730377503B3F31Fddb221AEd0883',
      'bridgeAddress': '0xc0e5bABFAdB32373ffaBe23a2b3A04D0280c35B4',
      'erc20handlerAddress': '0x03f62C58BCeebE3720eA206D69abA7A5EE1Aa4fE'
    },
  [ethereumChainId]: {
    'DAOBridgeAddress': '0x9DcD76b4A7357249d6160D456670bAcC53292e27',
    'bridgeAddress': '0x6Ab2A602d1018987Cdcb29aE6fB6E3Ebe44b1412',
    'erc20handlerAddress': '0xFe21Dd0eC80e744A473770827E1aD6393A5A94F0'
  },
  [bscChainId]: {
    'DAOBridgeAddress': '0x9DcD76b4A7357249d6160D456670bAcC53292e27',
    'bridgeAddress': '0x6Ab2A602d1018987Cdcb29aE6fB6E3Ebe44b1412',
    'erc20handlerAddress': '0xFe21Dd0eC80e744A473770827E1aD6393A5A94F0'
  },
  [bscTestnetChainId]: {
    'coldTreasuryDAOAddress': '0xE179a58d2609C8DF1C195C3D12B70C658F5347Fd',
    'hotTreasuryDAOAddress': '0xa78473aA1528345432E1D42f9AE743517F2a5652',
    'smartWalletFactoryDAOAddress': '0xe98cA86f35a9af0F4900b20216b76d8e69d3C58D'
  },
  [avalancheChainId]: {
    'DAOBridgeAddress': '0x9DcD76b4A7357249d6160D456670bAcC53292e27',
    'bridgeAddress': '0x6Ab2A602d1018987Cdcb29aE6fB6E3Ebe44b1412',
    'erc20handlerAddress': '0xFe21Dd0eC80e744A473770827E1aD6393A5A94F0'
  },
  [polygonChainId]: {
    'DAOBridgeAddress': '0x9DcD76b4A7357249d6160D456670bAcC53292e27',
    'bridgeAddress': '0x6Ab2A602d1018987Cdcb29aE6fB6E3Ebe44b1412',
    'erc20handlerAddress': '0xFe21Dd0eC80e744A473770827E1aD6393A5A94F0'
  },
  [fantomChainId]: {
    'DAOBridgeAddress': '0x8C14a978b251eaffdABef5aC48e15568E53D3477',
    'bridgeAddress': '0x400b3D2Ac98f93e14146E330210910f396f59C1E',
    'erc20handlerAddress': '0x598E5dBC2f6513E6cb1bA253b255A5b73A2a720b'
  },
  [baseChainId]: {
    DAOBridgeAddress: '0x9DcD76b4A7357249d6160D456670bAcC53292e27',
    bridgeAddress: '0x6Ab2A602d1018987Cdcb29aE6fB6E3Ebe44b1412',
    erc20handlerAddress: '0xFe21Dd0eC80e744A473770827E1aD6393A5A94F0'
  }
}

export const availableChainIds = [
  ultronChainId,
  ethereumChainId,
  bscChainId,
  avalancheChainId,
  polygonChainId,
  fantomChainId,
  ultronChainIdTestNet,
  fantomTestNetId,
  bscTestnetChainId,
  baseChainId
];

export const availableChains =
  {
    [ultronChainId]: {
      chainId: ultronChainId,
      chainName: ultronChainName,
      nativeCurrency: {
        name: 'ULX',
        symbol: 'ULX',
        decimals: 18
      },
      rpcUrls: [ultronRpcUrl],
      blockExplorerUrls: [ultronExplorerUrl],
      iconUrls: [
        'https://ultronswap.com/ulx-token.png',
        'https://ultronswap.com/ulx-token.svg'
      ]
    },
    [ethereumChainId]: {
      chainId: ethereumChainId,
      chainName: 'Ethereum',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18
      },
      rpcUrls: rpcUrls[ethereumChainId],
      iconUrls: null
    },
    [bscChainId]: {
      chainId: bscChainId,
      chainName: 'Binance',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
      },
      rpcUrls: rpcUrls[bscChainId],
      iconUrls: null
    },
    [bscTestnetChainId]: {
      chainId:  bscTestnetChainId,
      chainName: 'BNB Smart Chain Testnet',
      nativeCurrency: {
        name: 'tBNB',
        symbol: 'tBNB',
        decimals: 18
      },
      rpcUrls: rpcUrls[bscChainId],
      iconUrls: null
    },

    [avalancheChainId]: {
      chainId: avalancheChainId,
      chainName: 'Avalanche',
      nativeCurrency: {
        name: 'AVAX',
        symbol: 'AVAX',
        decimals: 18
      },
      rpcUrls: rpcUrls[avalancheChainId],
      iconUrls: null
    },
    [polygonChainId]: {
      chainId: polygonChainId,
      chainName: 'Polygon',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      rpcUrls: rpcUrls[polygonChainId],
      iconUrls: null
    },
    [fantomChainId]: {
      chainId: fantomChainId,
      chainName: 'Fantom',
      nativeCurrency: {
        name: 'FTM',
        symbol: 'FTM',
        decimals: 18
      },
      rpcUrls: rpcUrls[fantomChainId],
      iconUrls: null
    },
    [ultronChainIdTestNet] : {
      chainId: ultronChainIdTestNet,
      chainName: ultronChainNameTestNet,
      nativeCurrency: {
        name: 'ULX',
        symbol: 'ULX',
        decimals: 18
      },
      rpcUrls: [ultronRpcUrlTestNet],
      blockExplorerUrls: [ultronExplorerUrlTestNet],
      iconUrls: [
        'https://ultronswap.com/ulx-token.png',
        'https://ultronswap.com/ulx-token.svg'
      ]
    },
    [fantomTestNetId]: {
      chainId: `0x${Number(4002).toString(16)}`,
      chainName: 'Fantom testnet',
      nativeCurrency: {
        name: 'FTM',
        symbol: 'FTM',
        decimals: 18
      },
      rpcUrls: [' https://rpc.testnet.fantom.network/'],
      blockExplorerUrls: [' https://rpc.testnet.fantom.network/'],
      iconUrls: null
    },
    [baseChainId]: {
      chainId: baseChainId,
      chainName: 'Base',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
      },
      rpcUrls: ['https://base-rpc.publicnode.com'],
      blockExplorerUrls: ['https://basescan.org'],
      iconUrls: null
    }
  };
