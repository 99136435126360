import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { FormConnectWallet } from '../components/forms'
import { CustomTab } from '../components/ui'
import { Liquidity, Settings } from '../components/common'
import { useSupport } from '../hooks/useSupport'
import { Contract, ethers } from 'ethers'
import {
  DAOTreasuryAbiNew,
} from '../constant/treasury/DAOTreasuryAbiNew'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import DefaultAlert from '../components/common/DefaultAlert'
import { useMetaMask } from 'metamask-react'
import { availableChainIds, daoAddresses, providerUrls } from '../constant/chain/chain'
import { treasuryAbiNew } from '../constant/treasury/treasuryAbiNew'
import { makeDAOTreasuryObj } from '../utils/daoObjects/createDAOTreasuryObject'
import { useDAO } from '../hooks/useDAO'
import { daoStatuses } from '../constant/common/commonConst'
import { CircularLoader } from '../components/ui/CircularLoader'
import { daoRequestsSlice } from '../store/reducers/daoRequestsSlice'
// import { daoMapsSlice } from '../store/reducers/daoMapsSlice'
import { NewProposal } from '../components/forms/NewProposal'
import { find } from 'lodash'
import usePagination from '../hooks/usePagination'

const DaoTreasuryNew: React.FC = () => {
  const dispatch = useAppDispatch()

  const {connectWallet} = useSupport()
  const {isConnect} = useAppSelector(state => state.walletReducer)
  const {chainId} = useMetaMask()
// @ts-ignore
  const provider = useMemo(() => new ethers.providers.JsonRpcProvider(providerUrls[chainId], {
    chainId: parseInt(chainId, 16)
  }), [chainId])

  const {setTreasuryNewData} = daoRequestsSlice.actions

  const [tableData, setTableData] = useState([])
  const [DAOMap, setDAOMap] = useState([])
  const [alert, setAlert] = useState(null)
  const [votersList, setVotersList] = useState([])
  const [openProgress, setOpenProgress] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [currentDao, setCurrentDao] = useState(null)
  const [addresses, setAddresses] = useState([])

  const {makeDataForNewDao, getCurrentVoters, cancelRequest, approveRequest, sendVote} = useDAO(provider)
  const {currentPage, rowsPerPage, count, setCount, handlePageChange, handleRowsPerPageChange} = usePagination()

  const getData = async () => {

    try {
      setOpenProgress(true)
      const {signer} = await connectWallet()
      const DAOTreasuryAddress = daoAddresses[chainId]?.DAOTreasuryAddress
      const treasuryAddress = daoAddresses[chainId]?.treasuryAddress

      const DAO = new Contract(DAOTreasuryAddress, DAOTreasuryAbiNew, signer)
      const treasury = new Contract(treasuryAddress, treasuryAbiNew, signer)

      const daoActionMap = makeDAOTreasuryObj(DAO, treasury, DAOTreasuryAddress, provider)
      let selectedDao
      if(selectedType){
        selectedDao = find(daoActionMap, {type: selectedType})
      } else {
        setSelectedType(daoActionMap[0].type)
        selectedDao = find(daoActionMap, {type: daoActionMap[0].type})
      }
      const { votersList, voterCount } = await getCurrentVoters(DAO)
      const { tableData, lastId } = await makeDataForNewDao(selectedDao, voterCount, signer, rowsPerPage, currentPage);
      setCount(lastId)

      dispatch(setTreasuryNewData({requests: tableData, voters: votersList }))
      setAddresses([
        {name: `DAO Address: ${DAOTreasuryAddress}`},
        {name: `Treasury Address: ${treasuryAddress}`},
      ])
      setCurrentDao(selectedDao)
      setDAOMap(daoActionMap)
      setVotersList(votersList)
      setTableData(tableData)

    }catch (err) {
      console.log(err)
      setTableData([])
      setVotersList([])
      setAlert({open: true, type: 'error', text: 'something went wrong'})
    } finally {
      setOpenProgress(false)
    }
  }

  const makeVote = async (type, id, voteType) => {
    await sendVote(currentDao, type, id, setAlert, voteType)
  }

  const handleCancelRequest = async (type, id) => {
    await cancelRequest(currentDao, id, setAlert)
  }

  const handleApproveRequest = async (type, id) => {
    await approveRequest(currentDao, id, setAlert)
  }

  useEffect(() => {
    if(isConnect && availableChainIds.includes(chainId)){
      setOpenProgress(true)
      getData()
    } else {
      setTableData([])
    }
  }, [isConnect, chainId, selectedType, currentPage, rowsPerPage])



  // @ts-ignore
  return <>
    <CircularLoader
      open={openProgress}
    />
    <DefaultAlert
      open={alert?.open}
      type={alert?.type}
      text={alert?.text}
      onClose={() => setAlert(null)}
    />
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography variant='h4' sx={{ px: 2, mb: 4 }}>
          DAO treasury new
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormConnectWallet onChainChangeTrigger={handlePageChange}/>
      </Grid>
    </Grid>
    <CustomTab
      items={[
        {
          label: 'Settings',
          component:
            <Settings
              addresses={addresses}
              getData={getData}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              DAOMap={DAOMap}
              statuses={daoStatuses}
              tableData={tableData}
              makeVote={makeVote}
              handleCancelRequest={handleCancelRequest}
              handleApproveRequest={handleApproveRequest}
              votersList={votersList}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              count={count}
              onPageChange={handlePageChange}
              onRowCountChange={handleRowsPerPageChange}
            />,
        },
        {
          label: 'Liquidity',
          component: <Liquidity />,
        },
      ]}
    />
    {DAOMap.length &&
      <NewProposal
        daoMap={DAOMap}
        setAlert={setAlert}
      />
    }
  </>
}

export default DaoTreasuryNew
