

export const useDepositDAO = () => {
  const getStartEnd = (currentPage, rowsPerPage, lastIdNumber) => {
    const start = currentPage === 0 ? 1 : currentPage * rowsPerPage + 1
    let end = lastIdNumber
    if(currentPage === 0 && lastIdNumber > rowsPerPage){
      end = rowsPerPage
    } else if((currentPage * rowsPerPage) > lastIdNumber){
      end = currentPage * rowsPerPage
    }
    return {start, end}
  }
  const makeData = async (currentDao, voterCount, signer, rowsPerPage, currentPage) => {
 
    const res = []
      const lastId = await currentDao.getCounterRequest()
 
      const lastIdNumber = typeof lastId === 'number' ? lastId : lastId.toNumber()

      const {start, end} = getStartEnd(currentPage, rowsPerPage, lastIdNumber)
   
    for(let i = start; i <= end; i++){
        const id = lastId - (i-1)
        const request = await currentDao.getRequest(id)
      console.log(request)
        const parsedData = await currentDao.parseData(request, signer)
      
        const votes = await currentDao.getRequestVotes(id)
        let isAvailable = false
        try {
          isAvailable = currentDao.isRequestAvailable ?
            await currentDao.isRequestAvailable(id)
            :
            (Math.floor(voterCount/2) + 1) <= votes.toNumber()
        }catch (err) {
          // console.log(err)
        }
        const dataObj = {
          ...parsedData,
          votes: votes.toNumber(),
          isAvailable,
          type: currentDao.type,
          id: id,
          haveCancelFunc: !!currentDao.cancelRequest,
          hasRejectedButton: currentDao.trueFalseVote
        }
        res.push(dataObj)
      }
    return { tableData: res, lastId: lastIdNumber }
  }
 
  

  return {
    makeData
  }

}