const daoRevenueShareAbi = [
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'callerContract',
                'type': 'address'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'constructor'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'dao',
                'type': 'address'
            }
        ],
        'name': 'ChangeDAO',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'nft',
                'type': 'address'
            }
        ],
        'name': 'ChangeNFT',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'owner',
                'type': 'address'
            }
        ],
        'name': 'ChangeOwner',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': 'amount',
                'type': 'uint256'
            }
        ],
        'name': 'ChangeThreshold',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'token',
                'type': 'address'
            }
        ],
        'name': 'ChangeToken',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            },
            {
                'indexed': true,
                'internalType': 'bool',
                'name': 'include',
                'type': 'bool'
            }
        ],
        'name': 'ChangeVoter',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'enum DAOTypes.RequestType',
                'name': 'requestType',
                'type': 'uint8'
            },
            {
                'indexed': false,
                'internalType': 'bool',
                'name': 'voteType',
                'type': 'bool'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'sender',
                'type': 'address'
            },
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'requestId',
                'type': 'uint256'
            }
        ],
        'name': 'NewVoteForRequest',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'epoch',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': 'amount',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'bool',
                'name': 'is_raw',
                'type': 'bool'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'withdrawn_to',
                'type': 'address'
            }
        ],
        'name': 'Withdrawal',
        'type': 'event'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'cancelChangeDAORequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'cancelChangeNFTRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'cancelChangeOwnerRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'cancelChangeThresholdRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'cancelChangeTokenRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'cancelVoterRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'cancelWithdrawalRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'changeThresholdRequestConclusion',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'confirmChangeDAORequest',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'confirmChangeNFTRequest',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'confirmChangeOwnerRequest',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'confirmChangeTokenRequest',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'confirmWithdrawalRequest',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'countGetChangeDAOAffirmativeVotes',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'countGetChangeNFTAffirmativeVotes',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'countGetChangeOwnerAffirmativeVotes',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'countGetChangeThresholdAffirmativeVotes',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'countGetChangeTokenAffirmativeVotes',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'countGetVotersAffirmativeVotes',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'countGetWithdrawalAffirmativeVotes',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getActiveVotersCount',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'getChangeDAORequest',
        'outputs': [
            {
                'components': [
                    {
                        'internalType': 'address',
                        'name': 'dao',
                        'type': 'address'
                    },
                    {
                        'internalType': 'enum DAOTypes.RequestStatus',
                        'name': 'status',
                        'type': 'uint8'
                    }
                ],
                'internalType': 'struct DAOTypes.ChangeDAORequest',
                'name': '',
                'type': 'tuple'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getChangeDAORequestsCounter',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'getChangeNFTRequest',
        'outputs': [
            {
                'components': [
                    {
                        'internalType': 'address',
                        'name': 'nft',
                        'type': 'address'
                    },
                    {
                        'internalType': 'enum DAOTypes.RequestStatus',
                        'name': 'status',
                        'type': 'uint8'
                    }
                ],
                'internalType': 'struct DAOTypes.ChangeNFTRequest',
                'name': '',
                'type': 'tuple'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getChangeNFTRequestsCounter',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'getChangeOwnerRequest',
        'outputs': [
            {
                'components': [
                    {
                        'internalType': 'address',
                        'name': 'owner',
                        'type': 'address'
                    },
                    {
                        'internalType': 'enum DAOTypes.RequestStatus',
                        'name': 'status',
                        'type': 'uint8'
                    }
                ],
                'internalType': 'struct DAOTypes.ChangeOwnerRequest',
                'name': '',
                'type': 'tuple'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getChangeOwnerRequestsCounter',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'getChangeThresholdRequest',
        'outputs': [
            {
                'components': [
                    {
                        'internalType': 'uint256',
                        'name': 'amount',
                        'type': 'uint256'
                    },
                    {
                        'internalType': 'enum DAOTypes.RequestStatus',
                        'name': 'status',
                        'type': 'uint8'
                    }
                ],
                'internalType': 'struct DAOTypes.ChangeThresholdRequest',
                'name': '',
                'type': 'tuple'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getChangeThresholdRequestsCounter',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'getChangeTokenRequest',
        'outputs': [
            {
                'components': [
                    {
                        'internalType': 'address',
                        'name': 'token',
                        'type': 'address'
                    },
                    {
                        'internalType': 'enum DAOTypes.RequestStatus',
                        'name': 'status',
                        'type': 'uint8'
                    }
                ],
                'internalType': 'struct DAOTypes.ChangeTokenRequest',
                'name': '',
                'type': 'tuple'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getChangeTokenRequestsCounter',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getConsensusCount',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getThreshold',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'getVoterById',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'getVoterRequest',
        'outputs': [
            {
                'components': [
                    {
                        'internalType': 'address',
                        'name': 'candidate',
                        'type': 'address'
                    },
                    {
                        'internalType': 'bool',
                        'name': 'include',
                        'type': 'bool'
                    },
                    {
                        'internalType': 'enum DAOTypes.RequestStatus',
                        'name': 'status',
                        'type': 'uint8'
                    }
                ],
                'internalType': 'struct DAOTypes.VoterRequest',
                'name': '',
                'type': 'tuple'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getVoterRequestsCounter',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'getVoterStatusByAddress',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'getWithdrawalRequest',
        'outputs': [
            {
                'components': [
                    {
                        'internalType': 'uint256',
                        'name': 'epoch',
                        'type': 'uint256'
                    },
                    {
                        'internalType': 'uint256',
                        'name': 'amount',
                        'type': 'uint256'
                    },
                    {
                        'internalType': 'address',
                        'name': 'to',
                        'type': 'address'
                    },
                    {
                        'internalType': 'bool',
                        'name': 'is_raw_withdrawal',
                        'type': 'bool'
                    },
                    {
                        'internalType': 'enum DAOTypes.RequestStatus',
                        'name': 'status',
                        'type': 'uint8'
                    }
                ],
                'internalType': 'struct DAOTypes.WithdrawalRequest',
                'name': '',
                'type': 'tuple'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getWithdrawalRequestsCounter',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'isChangeDAOAvailable',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'isChangeNFTAvailable',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'isChangeOwnerAvailable',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'isChangeTokenAvailable',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'isWithdrawalAvailable',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            },
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            },
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            },
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'newChangeDAORequest',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'newChangeNFTRequest',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'newChangeOwnerRequest',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'newAmount',
                'type': 'uint256'
            }
        ],
        'name': 'newChangeThresholdRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'newChangeTokenRequest',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'bool',
                'name': 'voteType',
                'type': 'bool'
            },
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'newVoteForChangeDAORequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'bool',
                'name': 'voteType',
                'type': 'bool'
            },
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'newVoteForChangeNFTRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'bool',
                'name': 'voteType',
                'type': 'bool'
            },
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'newVoteForChangeOwnerRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'bool',
                'name': 'voteType',
                'type': 'bool'
            },
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'newVoteForChangeThresholdRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'bool',
                'name': 'voteType',
                'type': 'bool'
            },
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'newVoteForChangeTokenRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'bool',
                'name': 'voteType',
                'type': 'bool'
            },
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'newVoteForVoterRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'bool',
                'name': 'voteType',
                'type': 'bool'
            },
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'newVoteForWithdrawalRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'bool',
                'name': 'include',
                'type': 'bool'
            },
            {
                'internalType': 'address',
                'name': 'voterAddress',
                'type': 'address'
            }
        ],
        'name': 'newVoterRequest',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': '_epoch',
                'type': 'uint256'
            },
            {
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            },
            {
                'internalType': 'bool',
                'name': '_is_raw',
                'type': 'bool'
            },
            {
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'newWithdrawalRequest',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'votersRequestConclusion',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }
];

export default daoRevenueShareAbi;
