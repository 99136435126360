import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

export const createProposalForm = (schema) => {
  const itemList = []
  schema.map(el => {
    switch (el.type) {
      case 'addNew': {
        const data = {
          ...el,
          element: (onClick) =>
            <Grid item xs={el.xs} sx={{ mt: 1 }}>
              <Button
                fullWidth={true}
                variant={'contained'}
                onClick={onClick}
              >
                {el.name}
              </Button>
            </Grid>
        }
        itemList.push(data);
      }
      break;
      case 'text': {
        const data = {
          ...el,
          element: (value, onChange) =>
            <Grid item xs={el.xs} sx={{ mt: 1 }}>
              <TextField
                fullWidth={true}
                label={el.name}
                value={value}
                onChange={onChange}
                size={'small'}
              />
            </Grid>
        }
        itemList.push(data);
      }
      break;
      case 'boolean': {
        const data = {
          ...el,
          element: (value, onChange) =>
            <Grid item xs={el.xs} sx={{ mt: 1 }}>
              <FormControl sx={{minWidth: 120 }} fullWidth={true}>
                <InputLabel id={el.name}>
                  {el.name}
                </InputLabel>
                <Select
                  labelId={el.name}
                  label={el.name}
                  value={value}
                  onChange={onChange}
                  variant={'outlined'}
                  size={'small'}
                >
                  <MenuItem value={1}>
                    true
                  </MenuItem>
                  <MenuItem value={0}>
                    false
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
        }
        itemList.push(data)
      }
      break;
      case 'spacing': {
        const data = {
          element: () => <Grid item xs={el.xs} sx={{ mt: 1 }}/>
        }
        itemList.push(data)
      }
        break;
      default:  return null
    }
  })

  return itemList
}