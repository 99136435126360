import { useAppDispatch, useAppSelector } from './redux'
import { ethers } from 'ethers'
import { walletSlice } from '../store/reducers/walletSlice'

export const useSupport = () => {

  const {web3modal} = useAppSelector(state => state.web3modalReducer)
  const dispatch = useAppDispatch()
  const {setWalletInfo} = walletSlice.actions

  const connectWallet = async () => {
    try{
      const provider = await web3modal.connect();
      const library = new ethers.providers.Web3Provider(window.ethereum)
      const signer = await library.getSigner()
      library.pollingInterval = 12000;

      const account = await library.listAccounts()
      const signerAddress = account[0]
      dispatch(setWalletInfo({isConnect: true, address: signerAddress}))
      return {provider, library, signer, signerAddress}

    } catch (e) {
      console.log('error', e)
    }
  }

  return {connectWallet}
}