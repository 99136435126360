import { combineReducers, configureStore } from '@reduxjs/toolkit'
import web3modalReducer from './reducers/web3modalSlice'
import walletReducer from './reducers/walletSlice'
import daoReducer from './reducers/daoRequestsSlice'
import daoMapReducer from './reducers/daoMapsSlice'

const rootReducer = combineReducers({
  web3modalReducer,
  walletReducer,
  daoReducer,
  daoMapReducer
})

export const setupStore = () => configureStore({
  reducer: rootReducer
})

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']