export const config = {
  mainnet: false,
  ethereumChainId: '0x1',
  bscChainId: '0x38',
  bscTestnetChainId: '0x61',
  avalancheChainId: '0xa86a',
  polygonChainId: '0x89',
  fantomChainId: '0xfa',
  baseChainId: '0x2105',
  version: 0.1,
}
