import { useBridgeDAO } from '../../hooks/useBridgeDAO'
import { BigNumber } from 'ethers'
import { createERCWithdrawData, parseTokenAmountWithDecimals } from '../helpers'

export const makeDAOBridgeObject = (dao, daoAddress, bridge, erc20handler, signer, provider) => {

  const {getVoterStruct, getOwnerChangeStruct, getTransferStruct, getPauseStatusStruct, getChangeRelayerThresholdStruct,
    getSetResourceStruct, getSetGenericResourceStruct, getSetBurnableStruct, getSetNonceStruct,
    getSetForwarderStruct, getChangeFeeStruct, getChangeFeePercentStruct, getWithdrawStruct,
    getSetTreasuryStruct} = useBridgeDAO(provider)

  const mapDAO = [
    {
      type: 'voterRequest',
      name: 'Voter',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getVoterStruct(dao, daoAddress, BigNumber.from(4), rowsPerPage, currentPage),
      makeVote: async (type, id) => await dao.newVoteForVoterRequest(type, id),
      approveRequest: async (id) => await dao.votersRequestConclusion(id),
      cancelRequest: async (id) => await dao.cancelVoterRequest(id),
      createNewProposal: async (proposalData) => await dao.newVoterRequest(!!proposalData.include, proposalData.address),
      newProposalSchema: [
        {name: 'Include?', xs: 2, type: 'boolean', defaultValue: 1, daoName: 'include', formLink: 'include'},
        {name: 'Voter address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'},
      ]
    },
    {
      type: 'OwnerChangeRequest',
      name: 'Owner Change',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getOwnerChangeStruct(dao, daoAddress, BigNumber.from(7), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForOwnerChangeRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isOwnerChangeAvailable(id),
      cancelRequest: async (id) => await dao.cancelOwnerChangeRequest(id),
      approveRequest: async (id) => await bridge.renounceAdmin(id),
      createNewProposal: async (proposalData) => await dao.newOwnerChangeRequest(proposalData.address),
      newProposalSchema: [
        {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'},
      ]
    },
    {
      type: 'TransferRequest',
      name: 'Transfer',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getTransferStruct(dao, daoAddress, BigNumber.from(10), signer, rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForTransferRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isTransferAvailable(id),
      cancelRequest: async (id) => await dao.cancelTransferRequest(id),
      approveRequest: async (id) => await bridge.transferFunds(id),
      createNewProposal: async (proposalData) => {
        const addresses = []
        const amounts = []
        const parsedAmounts = []
        Object.entries(proposalData).map(([key, value]) => {
          if(key.startsWith('address') && value){
            addresses.push(value)
          } else if(key.startsWith('amount') && value){
            amounts.push(value)
          }
        })
        for(let i = 0; i < amounts.length; i++){
          const amount = await parseTokenAmountWithDecimals(addresses[i], amounts[i], signer)
          parsedAmounts.push(amount)
        }
        return await dao.newTransferRequest(addresses, parsedAmounts)
      },
      newProposalSchema: [
        {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address0'},
        {name: 'Amount', xs: 3, type: 'text', defaultValue: '', daoName: 'amount', formLink: 'amount0'},
        {name: 'Add more', xs: 2, type: 'addNew'},
        {xs: 3, type: 'spacing'},
      ],
      addingSchemaPart: (lastIndex) => {
        const index = (lastIndex - 2) / 2
        return [
          {xs: 5, type: 'spacing'},
          { name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: `address${index}` },
          { name: 'Amount', xs: 3, type: 'text', defaultValue: '', daoName: 'amount', formLink: `amount${index}` },
          { name: 'Add more', xs: 2, type: 'addNew' },
          {xs: 3, type: 'spacing'},
        ]
      }
    },
    {
      type: 'PauseStatusRequest',
      name: 'Pause Status',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getPauseStatusStruct(dao, daoAddress, BigNumber.from(13), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForPauseStatusRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isPauseStatusAvailable(id),
      cancelRequest: async (id) => await dao.cancelPauseStatusRequest(id),
      approveRequest: async (id) => await bridge.adminPauseStatusTransfers(id),
      createNewProposal: async (proposalData) => await dao.newPauseStatusRequest(!!proposalData.mode),
      newProposalSchema: [
        {name: 'Mode', xs: 2, type: 'boolean', defaultValue: 1, daoName: 'mode', formLink: 'mode'},
      ]
    },
    {
      type: 'ChangeRelayerThresholdRequest',
      name: 'Change Relayer Threshold',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getChangeRelayerThresholdStruct(dao, daoAddress, BigNumber.from(16), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForChangeRelayerThresholdRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isChangeRelayerThresholdAvailable(id),
      cancelRequest: async (id) => await dao.cancelChangeRelayerThresholdRequest(id),
      approveRequest: async (id) => await bridge.adminChangeRelayerThreshold(id),
      createNewProposal: async (proposalData) => await dao.newChangeRelayerThresholdRequest(proposalData.amount),
      newProposalSchema: [
        {name: 'Amount', xs: 3, type: 'text', defaultValue: '', daoName: 'amount', formLink: 'amount'},
      ]
    },
    {
      type: 'SetResourceRequest',
      name: 'Set Resource',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getSetResourceStruct(dao, daoAddress, BigNumber.from(19), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForSetResourceRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isSetResourceAvailable(id),
      cancelRequest: async (id) => await dao.cancelSetResourcRequest(id),
      approveRequest: async (id) => await bridge.adminSetResource(id),
      createNewProposal: async (proposalData) =>{
        return await dao.newSetResourceRequest(
          proposalData.handlerAddress,
          proposalData.resourceId,
          proposalData.tokenAddress
        )
      },
      newProposalSchema: [
        {name: 'Handler Address', xs: 4, type: 'text', defaultValue: '', daoName: 'handlerAddress', formLink: 'handlerAddress'},
        {name: 'Resource Id', xs: 4, type: 'text', defaultValue: '', daoName: 'resourceId', formLink: 'resourceId'},
        {name: 'Token Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'tokenAddress'},
      ]
    },
    {
      type: 'SetGenericResourceRequest',
      name: 'Set Generic Resource',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getSetGenericResourceStruct(dao, daoAddress, BigNumber.from(22), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForSetGenericResourceRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isSetGenericResourceAvailable(id),
      cancelRequest: async (id) => await dao.cancelSetGenericResourceRequest(id),
      approveRequest: async (id) => await bridge.adminSetGenericResource(id),
      createNewProposal: async (proposalData) => {
        return await dao.newSetGenericResourceRequest(
          proposalData.handlerAddress,
          proposalData.resourceId,
          proposalData.contractAddress,
          proposalData.depositFunctionSig,
          proposalData.depositFunctionDepositerOffset,
          proposalData.executeFunctionSig,
        )
      },
      newProposalSchema: [
        {name: 'Handler address', xs: 4, type: 'text', defaultValue: '', daoName: 'handlerAddress', formLink: 'handlerAddress'},
        {name: 'Contract address', xs: 4, type: 'text', defaultValue: '', daoName: 'contractAddress', formLink: 'contractAddress'},
        {name: 'Resource Id', xs: 3, type: 'text', defaultValue: '', daoName: 'resourceId', formLink: 'resourceId'},
        {type: 'spacing', xs: 1},
        {name: 'Deposit Function Depositer Offset', xs: 4, type: 'text', defaultValue: '', daoName: 'depositFunctionDepositerOffset', formLink: 'depositFunctionDepositerOffset'},
        {name: 'Deposit Function Sig', xs: 3, type: 'text', defaultValue: '', daoName: 'depositFunctionSig', formLink: 'depositFunctionSig'},
        {name: 'Execute Function Sig', xs: 3, type: 'text', defaultValue: '', daoName: 'executeFunctionSig', formLink: 'executeFunctionSig'},
      ]
    },
    {
      type: 'SetBurnableRequest',
      name: 'Set Burnable',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getSetBurnableStruct(dao, daoAddress, BigNumber.from(25), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForSetBurnableRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isSetBurnableAvailable(id),
      cancelRequest: async (id) => await dao.cancelSetBurnableRequest(id),
      approveRequest: async (id) => await bridge.adminSetBurnable(id),
      createNewProposal: async (proposalData) =>
        await dao.newSetBurnableRequest(proposalData.handlerAddress, proposalData.tokenAddress),
      newProposalSchema: [
        {name: 'Handler Address', xs: 4, type: 'text', defaultValue: '', daoName: 'handlerAddress', formLink: 'handlerAddress'},
        {name: 'Token Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'tokenAddress'},
      ]
    },
    {
      type: 'SetNonceRequest',
      name: 'Set Nonce',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getSetNonceStruct(dao, daoAddress, BigNumber.from(28), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForSetNonceRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isSetNonceAvailable(id),
      cancelRequest: async (id) => await dao.cancelSetNonceRequest(id),
      approveRequest: async (id) => await bridge.adminSetDepositNonce(id),
      createNewProposal: async (proposalData) =>
        await dao.newSetNonceRequest(proposalData.domainId, proposalData.nonce),
      newProposalSchema: [
        {name: 'Domain Id', xs: 3, type: 'text', defaultValue: '', daoName: 'domainId', formLink: 'domainId'},
        {name: 'Nonce', xs: 2, type: 'text', defaultValue: '', daoName: 'nonce', formLink: 'nonce'},
      ]
    },
    {
      type: 'SetForwarderRequest',
      name: 'Set Forwarder',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getSetForwarderStruct(dao, daoAddress, BigNumber.from(31), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForSetForwarderRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isSetForwarderAvailable(id),
      cancelRequest: async (id) => await dao.cancelSetForwarderRequest(id),
      approveRequest: async (id) => await bridge.adminSetForwarder(id),
      createNewProposal: async (proposalData) =>
        await dao.newSetForwarderRequest(proposalData.forwarder, !!proposalData.valid),
      newProposalSchema: [
        {name: 'Forwarder', xs: 4, type: 'text', defaultValue: '', daoName: 'forwarder', formLink: 'forwarder'},
        {name: 'Is valid', xs: 2, type: 'boolean', defaultValue: 1, daoName: 'valid', formLink: 'valid'},
      ]
    },
    {
      type: 'ChangeFeeRequest',
      name: 'Change Fee',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getChangeFeeStruct(dao, daoAddress, BigNumber.from(34), signer, rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForChangeFeeRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isChangeFeeAvailable(id),
      cancelRequest: async (id) => await dao.cancelChangeFeeRequest(id),
      approveRequest: async (id) => await bridge.adminChangeFee(id),
      createNewProposal: async (proposalData) => {
        const basiFee = await parseTokenAmountWithDecimals(proposalData.tokenAddress, proposalData.basicFee, signer)
        const minAmount = await parseTokenAmountWithDecimals(proposalData.tokenAddress, proposalData.minAmount, signer)
        const maxAmount = await parseTokenAmountWithDecimals(proposalData.tokenAddress, proposalData.maxAmount, signer)
        return  await dao.newChangeFeeRequest(
          proposalData.tokenAddress,
          proposalData.chainId,
          basiFee,
          minAmount,
          maxAmount,
        )
      },
      newProposalSchema: [
        {name: 'Token Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'tokenAddress'},
        {name: 'Chain Id', xs: 2, type: 'text', defaultValue: '', daoName: 'chainId', formLink: 'chainId'},
        {type: 'spacing', xs: 6},
        {name: 'Basic Fee', xs: 2, type: 'text', defaultValue: '', daoName: 'basicFee', formLink: 'basicFee'},
        {name: 'Min Amount', xs: 2, type: 'text', defaultValue: '', daoName: 'minAmount', formLink: 'minAmount'},
        {name: 'Max Amount', xs: 2, type: 'text', defaultValue: '', daoName: 'maxAmount', formLink: 'maxAmount'},
      ]
    },
    {
      type: 'ChangeFeePercentRequest',
      name: 'Change Fee Percent',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getChangeFeePercentStruct(dao, daoAddress, BigNumber.from(37), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForChangeFeePercentRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isChangeFeePercentAvailable(id),
      cancelRequest: async (id) => await dao.cancelChangeFeePercentRequest(id),
      approveRequest: async (id) => await bridge.adminChangeFeePercent(id),
      createNewProposal: async (proposalData) =>
        await dao.newChangeFeePercentRequest(proposalData.feeMaxValue, proposalData.feePercent),
      newProposalSchema: [
        {name: 'Fee Max Value', xs: 2, type: 'text', defaultValue: '', daoName: 'feeMaxValue', formLink: 'feeMaxValue'},
        {name: 'Fee Percent', xs: 2, type: 'text', defaultValue: '', daoName: 'feePercent', formLink: 'feePercent'},
      ]
    },
    {
      type: 'WithdrawRequest',
      name: 'Withdraw',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getWithdrawStruct(dao, daoAddress, BigNumber.from(40), signer, rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForWithdrawRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isWithdrawAvailable(id),
      cancelRequest: async (id) => await dao.cancelWithdrawRequest(id),
      approveRequest: async (id) => await bridge.adminWithdraw(id),
      createNewProposal: async (proposalData, signer) =>{
        const data = createERCWithdrawData(
          proposalData.tokenAddress, proposalData.recipientAddress, proposalData.tokenAmountOrID, signer
        )
        return await dao.newWithdrawRequest(proposalData.handlerAddress, data)
      },
      newProposalSchema: [
        {name: 'Handler Address', xs: 4, type: 'text', defaultValue: '', daoName: 'handlerAddress', formLink: 'handlerAddress'},
        {name: 'Token Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'tokenAddress'},
        {type: 'spacing', xs: 4},
        {name: 'Recipient Address', xs: 4, type: 'text', defaultValue: '', daoName: 'recipientAddress', formLink: 'recipientAddress'},
        {name: 'Token Amount Or ID', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAmountOrID', formLink: 'tokenAmountOrID'},
      ]
    },
    {
      type: 'SetTreasuryRequest',
      name: 'Set Treasury',
      trueFalseVote: true,
      getRequest: async (rowsPerPage, currentPage) => await getSetTreasuryStruct(dao, daoAddress, BigNumber.from(43), rowsPerPage, currentPage),
      makeVote: async (voteType, id) => await dao.newVoteForSetTreasuryRequest(voteType, id),
      isRequestAvailable: async (id) => await dao.isSetTreasuryAvailable(id),
      cancelRequest: async (id) => await dao.cancelSetTreasuryRequest(id),
      approveRequest: async (id) => await erc20handler.setTreasuryAddress(id),
      createNewProposal: async (proposalData) => await dao.newSetTreasuryRequest(proposalData.address),
      newProposalSchema: [
        {name: 'Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'},
      ]
    }
  ]

  return mapDAO
}