const revenueShareAbi = [
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'address',
                'name': 'previousAdmin',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'address',
                'name': 'newAdmin',
                'type': 'address'
            }
        ],
        'name': 'AdminChanged',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_admin',
                'type': 'address'
            }
        ],
        'name': 'AdminChanged',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'beacon',
                'type': 'address'
            }
        ],
        'name': 'BeaconUpgraded',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'bool',
                'name': '_changedTo',
                'type': 'bool'
            }
        ],
        'name': 'CanMakeDepositsAddressChanged',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_claimer',
                'type': 'address'
            }
        ],
        'name': 'Claimed',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_dao',
                'type': 'address'
            }
        ],
        'name': 'DAOChanged',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': '_nft_id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_owner',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_amount_part1',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_amount_part2',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': '_epoch',
                'type': 'uint256'
            }
        ],
        'name': 'FinishedAccrualForNFT',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': '_epoch',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_rspTotalByDay',
                'type': 'uint256'
            }
        ],
        'name': 'FinishedAccruals',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'address',
                'name': '_admin',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'address',
                'name': '_nft_contract',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_start_date',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'address',
                'name': '_token',
                'type': 'address'
            },
            {
                'indexed': false,
                'internalType': 'address',
                'name': '_dao',
                'type': 'address'
            }
        ],
        'name': 'Initialized',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_nft',
                'type': 'address'
            }
        ],
        'name': 'NFTChanged',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'previousOwner',
                'type': 'address'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'newOwner',
                'type': 'address'
            }
        ],
        'name': 'OwnershipTransferred',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'address',
                'name': 'account',
                'type': 'address'
            }
        ],
        'name': 'Paused',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'RawWithdrawn',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': '_epoch',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            }
        ],
        'name': 'RevenueReceivedForEpoch',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': '_epoch',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            }
        ],
        'name': 'StartedAccruals',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_token',
                'type': 'address'
            }
        ],
        'name': 'TokenChanged',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': false,
                'internalType': 'address',
                'name': 'account',
                'type': 'address'
            }
        ],
        'name': 'Unpaused',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'address',
                'name': 'implementation',
                'type': 'address'
            }
        ],
        'name': 'Upgraded',
        'type': 'event'
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'uint256',
                'name': '_epoch',
                'type': 'uint256'
            },
            {
                'indexed': false,
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            },
            {
                'indexed': true,
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'Withdrawn',
        'type': 'event'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            },
            {
                'internalType': 'uint256',
                'name': '_epoch',
                'type': 'uint256'
            },
            {
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            }
        ],
        'name': 'addRevenue',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'name': 'allowed_to_deposit',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'changeAdmin',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'changeDAO',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'changeGamingNFT',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'changeToken',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '_address',
                'type': 'address'
            },
            {
                'internalType': 'bool',
                'name': '_new_status',
                'type': 'bool'
            }
        ],
        'name': 'changeWhitelist',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': '_amount',
                'type': 'uint256'
            }
        ],
        'name': 'claim',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'name': 'claimable_amounts',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'contract_token_balance',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'currentEpoch',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'name': 'daily_balance_in_progress',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'name': 'daily_balance_undistributed',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'name': 'daily_last_in_progress_nft_id',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getContractDAO',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'getRevenueToken',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': '_admin',
                'type': 'address'
            },
            {
                'internalType': 'address',
                'name': '_nft_contract',
                'type': 'address'
            },
            {
                'internalType': 'address',
                'name': '_deposit_address',
                'type': 'address'
            },
            {
                'internalType': 'address',
                'name': '_token',
                'type': 'address'
            },
            {
                'internalType': 'address',
                'name': '_dao',
                'type': 'address'
            }
        ],
        'name': 'initialize',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'owner',
        'outputs': [
            {
                'internalType': 'address',
                'name': '',
                'type': 'address'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'pause',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'paused',
        'outputs': [
            {
                'internalType': 'bool',
                'name': '',
                'type': 'bool'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': '_epoch',
                'type': 'uint256'
            },
            {
                'internalType': 'uint256',
                'name': '_batch_size',
                'type': 'uint256'
            }
        ],
        'name': 'processAccruals',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'rawWithdrawUnaccounted',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'renounceOwnership',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'total_balance_claimed',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'total_balance_to_claim',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'total_unprocessed_balance',
        'outputs': [
            {
                'internalType': 'uint256',
                'name': '',
                'type': 'uint256'
            }
        ],
        'stateMutability': 'view',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'newOwner',
                'type': 'address'
            }
        ],
        'name': 'transferOwnership',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [],
        'name': 'unPause',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'newImplementation',
                'type': 'address'
            }
        ],
        'name': 'upgradeTo',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'address',
                'name': 'newImplementation',
                'type': 'address'
            },
            {
                'internalType': 'bytes',
                'name': 'data',
                'type': 'bytes'
            }
        ],
        'name': 'upgradeToAndCall',
        'outputs': [],
        'stateMutability': 'payable',
        'type': 'function'
    },
    {
        'inputs': [
            {
                'internalType': 'uint256',
                'name': 'id',
                'type': 'uint256'
            }
        ],
        'name': 'withdrawUnaccounted',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
    }
];

export default revenueShareAbi;
