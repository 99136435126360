import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  web3modal: {
    connect: () => {console.log(1)}
  }
};

export const web3modalSlice = createSlice({
  name: 'web3modal',
  initialState,
  reducers: {
    setWeb3Modal (state, action: PayloadAction<any>) {
      state.web3modal = action.payload
    },
  }
})

export const {setWeb3Modal} = web3modalSlice.actions
export default web3modalSlice.reducer
