import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Liquidity, Settings } from '../components/common'
import { CustomTab } from '../components/ui'
import { FormConnectWallet } from '../components/forms'
import { useSupport } from '../hooks/useSupport'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { useMetaMask } from 'metamask-react'
import { availableChainIds, daoAddresses, providerUrls} from '../constant/chain/chain'
import { Contract, ethers } from 'ethers'
import DefaultAlert from '../components/common/DefaultAlert'
import { useDAO } from '../hooks/useDAO'
import { shortStatuses } from '../constant/common/commonConst'
import { DAOTreasuryAbiOld } from '../constant/treasury-old/DAOTreasuryAbiOld'
import { treasuryAbiOld } from '../constant/treasury-old/treasuryAbiOld'
import { makeDAOTreasuryOldObject } from '../utils/daoObjects/createDAOTreasutyOldObject'
import { CircularLoader } from '../components/ui/CircularLoader'
import { daoRequestsSlice } from '../store/reducers/daoRequestsSlice'
import { NewProposal } from '../components/forms/NewProposal'
import { find } from 'lodash'
import usePagination from '../hooks/usePagination'

const DaoTreasuryOld: React.FC = () => {
  const dispatch = useAppDispatch()

  const {connectWallet} = useSupport()
  const {isConnect} = useAppSelector(state => state.walletReducer)
  const {chainId} = useMetaMask()
// @ts-ignore
  const provider = useMemo(() => new ethers.providers.JsonRpcProvider(providerUrls[chainId], {
    chainId: parseInt(chainId, 16)
  }), [chainId])

  const {setTreasuryOldData} = daoRequestsSlice.actions

  const [tableData, setTableData] = useState([])
  const [DAOMap, setDAOMap] = useState([])
  const [alert, setAlert] = useState(null)
  const [votersList, setVotersList] = useState([])
  const [openProgress, setOpenProgress] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [currentDao, setCurrentDao] = useState(null)
  const [addresses, setAddresses] = useState([])

  const {getCurrentVoters, sendVote, approveRequest, cancelRequest, makeTableData} = useDAO(provider)
  const {currentPage, rowsPerPage, count, setCount, handlePageChange, handleRowsPerPageChange} = usePagination()

  const getData = async () => {
    try {
      setOpenProgress(true)
      // @ts-ignore
      const {signer} = await connectWallet()
      const DAOTreasuryAddress = daoAddresses[chainId]?.DAOTreasuryAddressOld;
      const treasuryAddress = daoAddresses[chainId]?.treasuryAddressOld

      const DAOWulx = new Contract(DAOTreasuryAddress, DAOTreasuryAbiOld, signer)
      const wulx = new Contract(treasuryAddress, treasuryAbiOld, signer)

      const daoActionMap = makeDAOTreasuryOldObject(DAOWulx, wulx, DAOTreasuryAddress, signer, provider)
      let selectedDao
      if(selectedType){
        selectedDao = find(daoActionMap, {type: selectedType})
      } else {
        setSelectedType(daoActionMap[0].type)
        selectedDao = find(daoActionMap, {type: daoActionMap[0].type})
      }
      const { votersList, voterCount } = await getCurrentVoters(DAOWulx)
      const { tableData, lastId } = await makeTableData(selectedDao, voterCount, votersList, DAOTreasuryAddress, rowsPerPage, currentPage);
      setCount(lastId)

      dispatch(setTreasuryOldData({requests: tableData, voters: votersList }))
      setAddresses([
        {name: `DAO Address: ${DAOTreasuryAddress}`},
        {name: `Treasury Address: ${treasuryAddress}`},
      ])
      setDAOMap(daoActionMap)
      setCurrentDao(selectedDao)
      setVotersList(votersList)
      setTableData(tableData)
    } finally {
      setOpenProgress(false)
    }
  }

  const handleSendVote = async (type, id, voteType) => {
    await sendVote(currentDao, type, id, setAlert, voteType)
  }

  const handleApproveRequest = async (type, id) => {
    await approveRequest(currentDao, id, setAlert)
  }

  const handleCancelRequest = async (type, id) => {
    await cancelRequest(currentDao, id, setAlert)
  }

  useEffect(() => {
    if(isConnect && availableChainIds.includes(chainId)){
      setOpenProgress(true)
      getData()
    } else {
      setTableData([])
      // setAlert({open: true, type: 'error', text: 'unsupported chain'})
    }
  }, [isConnect, chainId, selectedType, currentPage, rowsPerPage])

  return (
    <>
      <CircularLoader
        open={openProgress}
      />
      <DefaultAlert
        open={alert?.open}
        type={alert?.type}
        text={alert?.text}
        onClose={() => setAlert(null)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant='h4' sx={{ px: 2, mb: 4 }}>
            Treasury old DAO
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormConnectWallet onChainChangeTrigger={handlePageChange}/>
        </Grid>
      </Grid>
      <CustomTab
        items={[
          {
            label: 'Settings',
            component:
              <Settings
                addresses={addresses}
                getData={getData}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                DAOMap={DAOMap}
                statuses={shortStatuses}
                tableData={tableData}
                makeVote={handleSendVote}
                handleCancelRequest={handleCancelRequest}
                handleApproveRequest={handleApproveRequest}
                votersList={votersList}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                count={count}
                onPageChange={handlePageChange}
                onRowCountChange={handleRowsPerPageChange}
              />,
          },
          {
            label: 'Liquidity',
            component: <Liquidity />,
          },
        ]}
      />
      {DAOMap.length &&
        <NewProposal
          daoMap={DAOMap}
          setAlert={setAlert}
        />
      }
    </>
  )
}

export default DaoTreasuryOld
