import { useDAO } from '../../hooks/useDAO'


export const makeDAOUniswapObj = (dao, factory, daoAddress, provider) => {
  const {getLastID} = useDAO(provider)

  const mapDAO = [
    {
      type: 'voterRequest',
      name: 'Voter',
      trueFalseVote: true,
      getCounterRequest: async () => await getLastID(6, daoAddress),
      getRequest: async (id) => await dao.getVoterRequest(id),
      getRequestVotes: async (id) => await dao.countGetVotersAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForVoterRequest(type, id),
      cancelRequest: async (id) => await dao.cancelVoterRequest(id),
      approveRequest: async (id) => await dao.votersRequestConclusion(id),
      createNewProposal: async (proposalData) => await dao.newVoterRequest(!!proposalData.include, proposalData.voterAddress),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            {label: data.include ? 'Add voter': 'Remove voter'},
            {label: `Address: ${data.candidate}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'Include?', xs: 2, type: 'boolean', defaultValue: 1, daoName: 'tokenAddress', formLink: 'include'},
        {name: 'Voter Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'voterAddress'}
      ]
    },
    {
      type: 'DAOChangeRequest',
      name: 'DAO Change',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getDAOChangeRequestCount(),
      getRequest: async (id) => await dao.getDAOChangeRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeDAOAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForDAOChangeRequest(type, id),
      cancelRequest: async (id) => await dao.cancelDAOChangeRequest(id),
      isRequestAvailable: async (id) => await dao.isDAOChangeAvailable(id),
      approveRequest: async (id) => await factory.setDao(id),
      createNewProposal: async (proposalData) => await dao.newDAOAddressChangeRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            {label: `Address: ${data.newDAOAddress}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'New Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'}
      ]
    },
    {
      type: 'feeToChangeRequest',
      name: 'Fee To Change',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getFeeToChangeRequestCount(),
      getRequest: async (id) => await dao.getFeeToChangeRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeFeeToAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForFeeToChangeRequest(type, id),
      cancelRequest: async (id) => await dao.cancelFeeToChangeRequest(id),
      isRequestAvailable: async (id) => await dao.isFeeToChangeAvailable(id),
      approveRequest: async (id) => await factory.setFeeTo(id),
      createNewProposal: async (proposalData) => await dao.newFeeToChangeRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            {label: `Address: ${data.newFeeToAddress}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'New Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'}
      ]
    },
    {
      type: 'routerChangeRequest',
      name: 'Router Change',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getRouterChangeRequestCount(),
      getRequest: async (id) => await dao.getRouterChangeRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeRouterAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForRouterChangeRequest(type, id),
      cancelRequest: async (id) => await dao.cancelRouterChangeRequest(id),
      isRequestAvailable: async (id) => await dao.isRouterChangeAvailable(id),
      approveRequest: async (id) => await factory.setRouterAddress(id),
      createNewProposal: async (proposalData) => await dao.newRouterChangeRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            {label: `Address: ${data.newRouterAddress}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'New Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'}
      ]
    },
    {
      type: 'treasuryChangeRequest',
      name: 'Treasury Change',
      trueFalseVote: true,
      getCounterRequest: async () => await dao.getTreasuryChangeRequestCount(),
      getRequest: async (id) => await dao.getTreasuryChangeRequest(id),
      getRequestVotes: async (id) => await dao.countGetChangeTreasuryAffirmativeVotes(id),
      makeVote: async (type, id) => await dao.newVoteForTreasuryChangeRequest(type, id),
      cancelRequest: async (id) => await dao.cancelTreasuryChangeRequest(id),
      isRequestAvailable: async (id) => await dao.isTreasuryChangeAvailable(id),
      approveRequest: async (id) => await factory.setTreasuryAddress(id),
      createNewProposal: async (proposalData) => await dao.newTreasuryChangeRequest(proposalData.address),
      parseData: (data) => {
        return {
          status: data.status,
          requestData: [
            {label: `Address: ${data.newTreasuryAddress}`}
          ]
        }
      },
      newProposalSchema: [
        {name: 'New Address', xs: 4, type: 'text', defaultValue: '', daoName: 'address', formLink: 'address'}
      ]
    }
  ]
  return mapDAO
}