import { useDAO } from '../../hooks/useDAO'


export const makeDAOPackageObj = (dao, packages, daoAddress, provider) => {
  const {getDAOData, getLastID} = useDAO(provider)

  const mapDAO = [
    {
      type: 'voterRequest',
      name: 'Voter',
      slot: 6,
      trueFalseVote: true,
      getCounterRequest: async () => await getLastID(6, daoAddress),
      getRequest: async (id) => await getDAOData(id,4, daoAddress),
      makeVote: async (type, id) => await dao.newVoteForVoterRequest(type, id),
      approveRequest: async (id) => await dao.votersRequestConclusion(id),
      createNewProposal: async (proposalData) => await dao.newVoterRequest(!!proposalData.include, proposalData.voterAddress),
      parseData: (data) => {
        const type = !!Number(data.substr(data.length - 43, 1))
        const obj = {
          status: Number(data.substr(data.length - 45, 1)),
          requestData: [
            {label: type ? 'Add voter': 'Remove voter'},
            {label: `Address: 0x${data.substr(data.length - 42, 40)}`}
          ]
        }
        return obj
      },
      newProposalSchema: [
        {name: 'Include?', xs: 2, type: 'boolean', defaultValue: 1, daoName: 'tokenAddress', formLink: 'include'},
        {name: 'Voter Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'voterAddress'}
      ]
    },
    {
      type: 'changeAdminRequests',
      name: 'Change Admin',
      slot: 9,
      trueFalseVote: false,
      getCounterRequest: async () => await getLastID(9, daoAddress),
      getRequest: async (id) => await getDAOData(id,7, daoAddress),
      makeVote: async (id) => await dao.newVoteForChangeAdminRequest(id),
      isRequestAvailable: async (id) => await dao.isChangeAdminAvailable(id),
      approveRequest: async (id) => await packages.changeAdmin(id),
      createNewProposal: async (proposalData) => await dao.newChangeAdminRequest(proposalData.newAddress),
      parseData: (data) => {
        const obj = {
          status: data.substr(data.length - 41, 1),
          requestData: [
            {label: `New address: 0x${data.substr(data.length - 40, 40)}`}
          ]
        }
        return obj
      },
      newProposalSchema: [
        {name: 'New Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'newAddress'}
      ]
    }
  ]
  return mapDAO
}