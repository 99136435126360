import { Box, Button, Divider, Grid, MenuItem, Select, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useDAO } from '../../hooks/useDAO'
import { createProposalForm } from '../../utils/createProposalForm'
import { useSupport } from '../../hooks/useSupport'
import { ethers } from 'ethers'
import { rpcUrls } from '../../constant/chain/chain'
import { useMetaMask } from 'metamask-react'

export const NewProposal = ({ daoMap, setAlert }: any) => {
  const { chainId } = useMetaMask()

  const provider = useMemo(
    () =>
      /* @ts-ignore */
      new ethers.providers.JsonRpcProvider(rpcUrls[chainId], {
        chainId: parseInt(chainId, 16),
      }),
    [chainId],
  )

  const { getCurrentDao } = useDAO(provider)
  const { connectWallet } = useSupport()

  const [proposalType, setProposalType] = useState(daoMap[0]?.type)
  const [proposalData, setProposalData] = useState({})
  const [proposalForm, setProposalForm] = useState([])
  const [isAddNew, setIsAddNew] = useState('init')
  const [error, setError] = useState('')

  const currentDao = useMemo(() => {
    return getCurrentDao(daoMap, proposalType || daoMap[0].type)
  }, [proposalType, daoMap])

  useMemo(() => {
    if (isAddNew === 'add') {
      const schema = currentDao?.addingSchemaPart(proposalForm.length)
      const newFormPart = createProposalForm(schema)
      const draftForm = [...proposalForm]
      draftForm.splice(proposalForm.length - 2, 2)
      setProposalForm([...draftForm, ...newFormPart])
      setIsAddNew('no update')
    } else if (isAddNew === 'init') {
      const schema = currentDao?.newProposalSchema
      const newData = {}
      schema?.map((el) => {
        newData[el.formLink] = el.defaultValue
      })
      setProposalData(newData)
      setProposalForm(createProposalForm(schema))
    }
  }, [proposalType, isAddNew])

  const handleChangeProposalType = (value) => {
    setIsAddNew('init')
    setProposalType(value)
  }

  const handleChange = (e, formLink) => {
    if (currentDao.proposalValidation) {
      currentDao.proposalValidation(e.target.value, setError)
    }
    setProposalData((prevState) => ({ ...prevState, [formLink]: e.target.value }))
  }

  const handleAddNew = () => {
    setIsAddNew('add')
  }

  const handleSubmitProposal = async () => {
    try {
      const { signer } = await connectWallet()
      console.log(proposalData)
      await currentDao.createNewProposal(proposalData, signer)
    } catch (err) {
      console.log(err)
      setAlert({ open: true, type: 'error', text: err?.data?.message || err.message })
    }
  }

  return (
    <Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Typography variant={'h4'}>Create proposal</Typography>
        <Select
          value={proposalType}
          onChange={(e) => handleChangeProposalType(e.target.value)}
          variant={'outlined'}
          sx={{ minWidth: 220, mt: 2 }}
          size={'small'}
        >
          {daoMap?.map((el, i) => (
            <MenuItem key={el.type + i} value={el.type}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
        <Grid
          container
          spacing={1}
          sx={{ maxWidth: proposalForm.length > 10 ? 'fit-content' : 'inherit' }}
        >
          {proposalForm.map((el) =>
            el.type === 'addNew'
              ? el.element(handleAddNew)
              : el.element(proposalData[el.formLink], (e) => handleChange(e, el.formLink)),
          )}
        </Grid>
        {Boolean(error) && (
          <Typography paragraph sx={{ fontSize: 12, color: '#ec942c' }}>
            {error}
          </Typography>
        )}

        <Box sx={{ mt: 2 }}>
          <Button variant={'outlined'} onClick={handleSubmitProposal}>
            Submit Proposal
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
