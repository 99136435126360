import {
  parseTokenAmount,
  parseTokenAmountWithDecimals,
} from '../helpers'
import { useDAO } from '../../hooks/useDAO'


export const makeDAOTreasuryOldObject = (dao, treasury, daoAddress, signer, provider) => {
  const {getDAOData, getLastID, getTransferNativeRequestData, getTransferRequestData} = useDAO(provider)

  const mapDAO = [
    {
      type: 'voterRequest',
      name: 'Voter',
      slot: 6,
      trueFalseVote: true,
      getCounterRequest: async () => await getLastID(6, daoAddress),
      getRequest: async (id) => await getDAOData(id,4, daoAddress),
      makeVote: async (type, id) => await dao.newVoteForVoterRequest(type, id),
      approveRequest: async (id) => await dao.votersRequestConclusion(id),
      createNewProposal: async (proposalData) => await dao.newVoterRequest(!!proposalData.include, proposalData.voterAddress),
      parseData: (data) => {
        const type = !!Number(data.substr(data.length - 43, 1))
        const obj = {
          status: Number(data.substr(data.length - 45, 1)),
          requestData: [
            {label: type ? 'Add voter': 'Remove voter'},
            {label: `Address: 0x${data.substr(data.length - 42, 40)}`}
          ]
        }
        return obj
      },
      newProposalSchema: [
        {name: 'Include?', xs: 2, type: 'boolean', defaultValue: 1, daoName: 'tokenAddress', formLink: 'include'},
        {name: 'Voter Address', xs: 4, type: 'text', defaultValue: '', daoName: 'tokenAddress', formLink: 'voterAddress'}
      ]
    },
    {
      type: 'transferTokensRequests',
      name: 'Transfer Tokens',
      slot: 9,
      trueFalseVote: false,
      getCounterRequest: async () => await getLastID(9, daoAddress),
      getRequest: async (id) => await getTransferRequestData(id,7, daoAddress, signer),
      makeVote: async (id) => {
        return await dao.newVoteForTransferTokensRequest(id)
      },
      isRequestAvailable: async (id) => await dao.isTransferTokensAvailable(id),
      approveRequest: async (id) => await treasury.withdrawalTokens(id),
      createNewProposal: async (proposalData, signer) => {
        const amount = await parseTokenAmountWithDecimals(proposalData.token, proposalData.amount, signer)
        return await dao.newTransferTokensRequest(proposalData.recepient, proposalData.token, amount)
      },
      parseData: (data) => {
        const obj = {
          status: data.status,
          requestData: [
            {label: `Address: ${data.address}`},
            {label: `Token address: ${data.tokenAddress}`},
            {label: `Amount: ${data.amount}`}
          ]
        }
        return obj
      },
      newProposalSchema: [
        {name: 'Recepient', xs: 4, type: 'text', defaultValue: '', daoName: 'recepient', formLink: 'recepient'},
        {name: 'Token', xs: 4, type: 'text', defaultValue: '', daoName: 'token', formLink: 'token'},
        {name: 'Amount', xs: 4, type: 'text', defaultValue: '', daoName: 'amount', formLink: 'amount'},
      ]
    },
    {
      type: 'transferNativeRequests',
      name: 'Transfer Native',
      slot: 12,
      trueFalseVote: false,
      getCounterRequest: async () => await getLastID(12, daoAddress),
      getRequest: async (id) => await getTransferNativeRequestData(id,10, daoAddress),
      makeVote: async (id) => await dao.newVoteForTransferNativeRequest(id),
      isRequestAvailable: async (id) => await dao.isTransferNativeAvailable(id),
      approveRequest: async (id) => await treasury.withdrawalNative(id),
      createNewProposal: async (proposalData) => {
        const amount = parseTokenAmount(proposalData.amount, 18)
        return await dao.newTransferNativeRequest(proposalData.recepient, amount)
      },
      parseData: (data) => {
        const obj = {
          status: data.status,
          requestData: [
            {label: `Address: ${data.address}`},
            {label: `Amount: ${data.amount}`}
          ]
        }
        return obj
      },
      newProposalSchema: [
        {name: 'Recepient', xs: 4, type: 'text', defaultValue: '', daoName: 'recepient', formLink: 'recepient'},
        {name: 'Amount', xs: 4, type: 'text', defaultValue: '', daoName: 'amount', formLink: 'amount'},
      ]
    }
  ]
  return mapDAO
}