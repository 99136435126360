import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App'
import { CssBaseline } from '@mui/material'
import { MetaMaskProvider } from 'metamask-react'
import { Provider } from 'react-redux'
import { setupStore } from './store'

const store = setupStore()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MetaMaskProvider>
        <CssBaseline />
        <App />
      </MetaMaskProvider>
    </Provider>
  </React.StrictMode>,
)
